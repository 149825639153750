import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Select,
  Modal,
  InputNumber,
} from 'antd';
import {
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import './style.css';
import { ContractActions } from '../../../actions';

function CreateForm({ visible, onCancel }) {
  const { Option } = Select;
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { clientsList } = useSelector((state) => state.ClientsReducer);
  const { contract } = useSelector((state) => state.ContractsReducer);

  async function handleContractCreation({
    plan_id = 10,
    crm_id = 2,
    is_active,
    max_device,
    max_active,
    plan_type = '1',
    day_debit = '05',
    client_id,
  }) {
    // const debit_day = day_debit.toString();
    // const parsed_debit_day = debit_day.length > 1
    //   ? debit_day
    //   : debit_day.padStart(2, '0');

    contract.plan_id = plan_id;
    contract.crm_id = crm_id;
    contract.is_active = is_active;
    contract.max_device = max_device;
    contract.max_active = max_active;
    contract.plan_type = plan_type;
    contract.day_debit = day_debit;
    contract.client_id = client_id;

    confirm({
      title: 'Confirma o cadastro do contrato ?',
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk() {
        dispatch(ContractActions.async_add_contract(contract));
      },
    });
  }

  return (
    <Modal
      title="Cadastro de contrato"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      width={450}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleContractCreation}
        size="large"
        // style={{ width: '100%' }}
      >

        <Form.Item
          label="Cliente"
          name="client_id"
          tooltip={{
            title: 'Selecione o cliente que o contrato está associado',
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: 'Informe a qual cliente este contrato será associado.',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Selecione o Cliente"
            optionFilterProp="children"
            filterOption={
                  (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
          >
            {clientsList.map(
              (client) => (
                <Option key={client.id} value={client.id}>
                  {client.company_name}
                </Option>
              ),
            )}
          </Select>
        </Form.Item>

        <Form.Item
          label="Status do contrato"
          name="is_active"
          rules={[]}
        >
          <Select
            showSearch
            placeholder="Selecione o Cliente"
            optionFilterProp="children"
            filterOption={
                  (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
          >
            <Option key="activated-contract" value>Ativado</Option>
            <Option key="disabled-contract" value={false}>Desativado</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="CRM ID"
          name="crm_id"
          rules={[]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Max. dispositivos"
          name="max_device"
          tooltip={{
            title: 'Quantidade máxima de dispositivos',
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: 'Informe a quantidade máxima de dispositivos.',
            },
          ]}
        >
          <InputNumber style={{ width: '100%' }} min={1} max={100000} />
        </Form.Item>

        <Form.Item
          label="Max. dispositivos ativos"
          name="max_active"
          tooltip={{
            title: 'Quantidade máxima de dispositivos que podem ser ativados',
            icon: <InfoCircleOutlined />,
          }}
          rules={[]}
        >
          <InputNumber style={{ width: '100%' }} min={1} max={100000} />
        </Form.Item>

        <Form.Item
          label="Tipo do plano"
          name="plan_type"
          rules={[]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item
          label="Dia de débito"
          name="day_debit"
          rules={[]}
        >
          <InputNumber style={{ width: '50%' }} min={1} max={28} />
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            Cadastrar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

CreateForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateForm;
