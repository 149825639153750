import { notification } from 'antd';
import { put } from 'redux-saga/effects';

import api from '../../services/api';
import { UsersActions } from '../../actions';

export function* get_all_users({ limit, offset }) {
  try {
    const { data } = yield api.get('/users', { params: { limit, offset } })
      .then((response) => response);

    if (data.length > 0) {
      data.map((i) => Object.assign(i, { key: `${i.id}` }));
    }

    const sortedUsers = data.sort(
      (a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
        return 0;
      },
    );

    yield put(UsersActions.show_users(sortedUsers));
  } catch (warning) {
    notification.warning({
      message: 'Sessão expirou!',
      description: 'Faça o login novamente.',
    });
  }
}

export function* getUserByID(id) {
  const { data } = yield api.get(`/users/${id}`).then((response) => response);
  data.key = `${data.id}`;
  return data;
}

export function* addUser({ user_data }) {
  try {
    const userData = yield api.post('/users/create-user', user_data).then((response) => response);
    notification.success({ message: 'Usuário registrado com sucesso!' });

    const newUser = yield getUserByID(userData.data.id);
    yield put(UsersActions.add_user(newUser));
  } catch (error) {
    notification.error({
      message: 'Erro no registro!',
      description: 'Ocorreu um erro ao registrar o usuário. Revise as informações e tente novamente.',
    });
  }
}

export function* deleteUser({ user_list_id }) {
  try {
    yield Promise.all(
      user_list_id.map((id) => api.delete(`/users/${id}`)),
    );

    if (user_list_id.length > 1) {
      notification.success({ message: 'Usuários deletados.' });
    } else {
      notification.success({ message: 'Usuário deletado.' });
    }
  } catch (error) {
    notification.error({
      message: 'Erro na remoção do usuário!',
      description: 'Ocorreu um erro no processo, verifique sua conexão.',
    });
  }

  yield put(UsersActions.delete_user(user_list_id[0]));
}

export function* updateUser({ user_id, user_data }) {
  try {
    yield api.patch(`/users/${user_id}`, user_data);
    notification.success({ message: 'Usuário editado.' });
  } catch (error) {
    notification.error({ message: 'Erro na edição do usuário!' });
  }

  const userUpdated = yield getUserByID(user_id);
  yield put(UsersActions.update_user(user_id, userUpdated));
}

export function* changePassword({ password_info }) {
  try {
    yield api.post('/users/change-password', password_info);
    notification.success({ message: 'Senha alterada.' });
  } catch (error) {
    notification.error({
      message: 'Erro na alteração da senha!',
      description: 'Certifique-se que a senha antiga está correta.',
    });
  }
}

export function* getOneUser({ user_id }) {
  try {
    yield api.get(`/users/${user_id}`).then(
      notification.success({ message: 'Usuário encontrado.' }),
    );
  } catch (error) {
    notification.error({
      message: 'Falha na pesquisa do usuário!',
      description: 'Usuário não encontrado ou falha na conexão.',
    });
  }
}

export function* activateUser({ listOfUsers, enabled }) {
  const method = enabled ? 'ativação' : 'desativação';
  const successMessage = enabled ? 'ativado(s)' : 'desativado(s)';

  try {
    yield Promise.all(
      listOfUsers.map((id) => api.patch(`/users/${id}`, { is_active: enabled })),
    );

    if (listOfUsers.length > 1) {
      notification.success({ message: `Usuários ${successMessage}.` });
    } else {
      notification.success({ message: `Usuário ${successMessage}.` });
    }
  } catch (error) {
    notification.error({
      message: `Erro na ${method} do usuário!`,
      description: 'Ocorreu um erro no processo, verifique sua conexão.',
    });
  }
}
