import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { notification } from 'antd';

import api from '../../services/api';
import {
  AuthActions,
  ClientsActions,
  ContractActions,
  DevicesActions,
  UsersActions,
  TagsActions,
} from '../../actions';

function* signIn({ email, password }) {
  yield put(AuthActions.sign_in_request_loading());

  try {
    const response = yield call(api.post, 'token', { email, password });
    const { access_token, user_type } = response.data;

    localStorage.setItem('token', access_token);
    localStorage.setItem('user', user_type);

    yield put(AuthActions.login_success(access_token, user_type));

    if (user_type === 1) {
      notification.success({ message: 'Bem vindo!' });
      yield put(push('/'));
    } else if (user_type === 2 || user_type === 3) {
      notification.success({ message: 'Bem vindo!' });
      yield put(push('/home-view'));
    }
  } catch (error) {
    notification.error({
      message: 'Falha no login!',
      description: 'O email ou a senha estão incorretos! Tente novamente.',
    });
    yield put(AuthActions.login_failure());
  }
}

function* signOut() {
  localStorage.removeItem('token');
  localStorage.removeItem('user');

  yield put(ClientsActions.clear_client_list());
  yield put(DevicesActions.clear_devices_list());
  yield put(ContractActions.clear_contracts_list());
  yield put(UsersActions.clear_users_list());
  yield put(TagsActions.clear_tags_list());

  yield put(AuthActions.logout_success());
  yield put(push('/login'));
}

export { signIn, signOut };
