import { ACTION_TYPES } from '../../actions/contractActions';

const INITIAL_STATE = {
  contractsList: [],

  contract: {
    plan_id: 10,
    crm_id: 20,
    is_active: 'True',
    max_device: 100,
    max_active: 50,
    plan_type: '1',
    day_debit: '01',
    client_id: 20,
    id: 231,
    // counter_device: 5,
    // counter_active: 1,
    // created_at: '2021-01-01 10:13:46',
    // updated_at: '2021-01-01 10:13:46',
    // activation_date: '2021-01-01 10:13:46',
    // deactivation_date: '2021-01-01 10:13:46',
  },

  loadMore: true,
};

const ContractsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_CONTRACTS_LIST:
      return { ...state, contractsList: [...state.contractsList, ...action.contracts_data] };

    case ACTION_TYPES.ENABLE_LOAD_MORE:
      return { ...state, loadMore: action.value };

    case ACTION_TYPES.CLEAR_CONTRACTS_LIST:
      return { ...state, contractsList: [], loadMore: true };

    case ACTION_TYPES.UPDATE_CONTRACT:
      return {
        ...state,
        contractsList: state.contractsList.map((contract) => {
          if (contract.id === action.newData.id) {
            return {
              plan_id: action.newData.plan_id,
              crm_id: action.newData.crm_id,
              is_active: action.newData.is_active,
              max_device: action.newData.max_device,
              max_active: action.newData.max_active,
              plan_type: action.newData.plan_type,
              day_debit: action.newData.day_debit,
              client_id: action.newData.client_id,
              id: action.newData.id,
              counter_device: action.newData.counter_device,
              counter_active: action.newData.counter_active,
              created_at: action.newData.created_at,
              updated_at: action.newData.updated_at,
              activation_date: action.newData.activation_date,
              deactivation_date: action.newData.deactivation_date,
            };
          }

          return contract;
        }),
      };

    case ACTION_TYPES.DELETE_CONTRACT:
      return {
        ...state,
        contractsList: state.contractsList.filter(
          (contract) => contract.id !== action.id,
        ),
      };

    case ACTION_TYPES.ADD_CONTRACT:
      return {
        ...state,
        contractsList: [...state.contractsList, action.newContract],
      };

    default:
      return state;
  }
};

export default ContractsReducer;
