import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, BrowserRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  UserOutlined,
  ApartmentOutlined,
  ExportOutlined,
  ContainerOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
  LogoutOutlined,
  TagsOutlined,
} from '@ant-design/icons';

import './style.css';
import SubRoutes from './subRoutes';
import {
  SiderActions,
  AuthActions,
} from '../../actions';
import PrivateRoute from '../../routes/private';

function Dashboard() {
  const { Sider } = Layout;
  const { SubMenu } = Menu;

  const [newClass, setNewClass] = useState('closed');
  const dispatch = useDispatch();

  const { clientsList } = useSelector((state) => state.ClientsReducer);
  const { devicesList } = useSelector((state) => state.DevicesReducer);
  const { contractsList } = useSelector((state) => state.ContractsReducer);
  const { collapsed } = useSelector((state) => state.SiderReducer);

  // dispara ação para alteração do valor de collapsed
  function handleCollapsed() {
    dispatch(SiderActions.set_collapsed(!collapsed));

    if (collapsed) {
      setNewClass('opened');
    } else {
      setNewClass('closed');
    }
  }

  useEffect(() => { }, [clientsList, devicesList, contractsList, dispatch]);

  return (
    <BrowserRouter>
      <div className="dashboard-container">
        <Layout>
          <Sider
            collapsible
            className="side"
            collapsed={collapsed}
            onCollapse={handleCollapsed}
          >
            <Menu theme="dark" mode="inline">
              <Menu.Item key="over-view" title="Geral" icon={<HomeOutlined />}>
                <Link to="/">Home</Link>
              </Menu.Item>

              <SubMenu key="sub1" icon={<UserOutlined />} title="Cliente">
                <Menu.Item key="sub1-1">
                  <Link to="/client-register">Cadastrar Cliente</Link>
                </Menu.Item>

                <Menu.Item key="sub1-2">
                  <Link to="/clients-management">Gerenciar Clientes</Link>
                </Menu.Item>

              </SubMenu>

              <SubMenu
                key="sub2"
                icon={<ApartmentOutlined />}
                title="Dispositivos"
              >
                <Menu.Item key="sub2-1">
                  <Link to="/device-register">Cadastrar Dispositivo</Link>
                </Menu.Item>
                <Menu.Item key="sub2-2">
                  <Link to="/device-management">
                    Gerenciar Dispositivos
                  </Link>
                </Menu.Item>
              </SubMenu>

              <Menu.Item
                key="user-management"
                icon={<UsergroupAddOutlined />}
                title="Gerenciar usuários"
              >
                <Link to="/users">Gerenciar Usuários</Link>
              </Menu.Item>

              <Menu.Item key="contracts" icon={<ContainerOutlined />}>
                <Link to="/contracts">Gerenciar Contratos</Link>
              </Menu.Item>

              <Menu.Item key="migration" icon={<ExportOutlined />}>
                <Link to="/migration">Migração</Link>
              </Menu.Item>

              <Menu.Item key="tags" icon={<TagsOutlined />}>
                <Link to="/tags">Tags</Link>
              </Menu.Item>

              <Menu.Item key="user-settings" icon={<SettingOutlined />}>
                <Link to="/user-settings">Configurações da conta</Link>
              </Menu.Item>

              <Menu.Item
                title="Sair"
                icon={<LogoutOutlined />}
                onClick={() => dispatch(AuthActions.logout())}
              >

                <Link to="/login">
                  Sair
                </Link>
              </Menu.Item>

            </Menu>

          </Sider>

          <div className={newClass}>
            {SubRoutes.map((DashRoutes) => (
              <PrivateRoute
                key={`${DashRoutes.key}`}
                path={DashRoutes.path}
                exact={DashRoutes.exact}
                component={DashRoutes.component}
              />
            ))}
          </div>

        </Layout>

      </div>
    </BrowserRouter>
  );
}

export default Dashboard;
