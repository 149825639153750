import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Modal,
  Input,
  Button,
  Select,
} from 'antd';
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { TagsActions } from '../../../actions';

function NewTagModal({ visible, onCancel }) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { confirm } = Modal;
  const dispatch = useDispatch();

  const { clientsList } = useSelector((state) => state.ClientsReducer);

  function onFinish({ client_id, name }) {
    confirm({
      title: 'Deseja confirmar o registro da tag?',
      icon: <ExclamationCircleOutlined />,

      onOk() {
        dispatch(TagsActions.async_add_tag({ client_id, name }));
      },
    });
  }

  return (
    <Modal
      title="Cadastrar tag"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      keyboard
      width={500}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item label="Cliente" name="client_id">
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={
              (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          >
            {clientsList.map(
              (client) => (
                <Option key={client.id} value={client.id}>
                  {client.company_name}
                </Option>
              ),
            )}
          </Select>
        </Form.Item>

        <Form.Item label="Tag" name="name">
          <Input />
        </Form.Item>

        <Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
          >
            Registrar
          </Button>

        </Form.Item>

      </Form>
    </Modal>
  );
}

NewTagModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default NewTagModal;
