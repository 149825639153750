import { ACTION_TYPES } from '../../actions/clientActions';

const INITIAL_STATE = {
  clientsList: [],
  loading: false,
  loadMoreClients: true,
  clientTempList: [],
  client: {
    id: 0,
    cpf_cnpj: '',
    company_name: '',
    email: '',
    is_active: false,
    c_type: '',
    phone: '',
    created_at: '',
    updated_at: '',
  },

  test: 0,
};

const ClientsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.ADD_CLIENT:
      return {
        ...state,
        clientsList: [...state.clientsList, action.newClient],
      };

    case ACTION_TYPES.SHOW_CLIENTS:
      return {
        ...state,
        clientsList: [...state.clientsList, ...action.payload],
        loading: true,
      };

    case ACTION_TYPES.SHOW_ONE_CLIENT:// get de info de um cliente
      return {
        ...state,
        client: action.clientInfo,
        loading: true,
      };// action utilizada no dashboard do client ou provider

    case ACTION_TYPES.LOAD_MORE_CLIENTS:
      return { ...state, loadMoreClients: action.value };

    case ACTION_TYPES.CLEAR_CLIENT_LIST:
      return { ...state, clientsList: [] };

    case ACTION_TYPES.UPDATE_CLIENT:
      return {
        ...state,
        clientsList: state.clientsList.map((client) => {
          if (client.id === action.id) {
            return {
              c_type: action.newData.c_type,
              company_name: action.newData.company_name,
              cpf_cnpj: action.newData.cpf_cnpj,
              email: action.newData.email,
              id: action.id,
              is_active: action.newData.is_active,
              key: action.id,
              phone: action.newData.phone,
              provider_id: action.newData.provider_id,
              updated_at: action.newData.updated_at,
            };
          }
          return client;
        }),
      };

    case ACTION_TYPES.DELETE_CLIENT:
      return {
        ...state,
        clientsList: state.clientsList.filter((client) => client.id !== action.id),
      };

    default:
      return state;
  }
};

export default ClientsReducer;
