import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import {
  Table,
  Modal,
  Button,
  Space,
  Input,
  Tag,
  Card,
  Tooltip,
  Dropdown,
  Menu,
} from 'antd';
import {
  ExclamationCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
  // ApiOutlined,
  // FilterOutlined,
  AppstoreAddOutlined,
  MoreOutlined,
  TagOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

import { DevicesActions, ContractActions } from '../../actions';
import DeviceRegister from './newDeviceForm';
import ManageConnections from './components/connections';
import ManageFilters from './components/filters';
import DevUpdateModal from './subComponents/devUpdateModal';
import NewTagModal from './newTagModal/newTagModal';

function ClientDevicesTable() {
  const dispatch = useDispatch();

  const { devicesList, loadMoreDevices, loading } = useSelector((state) => state.DevicesReducer);
  const { loadMore } = useSelector((state) => state.ContractsReducer);
  const { u_type } = useSelector((state) => state.AuthReducer);
  const { tagsList } = useSelector((state) => state.TagsReducer);
  const { client } = useSelector((state) => state.ClientsReducer);

  const [formValues, setFormValues] = useState({});
  const [modalsState, setModalsState] = useState({
    newTagModal: false,
    filterModal: false,
    updateDeviceModal: false,
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [connectionModal, setConnectionModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [updateModalForm, setupdateModalForm] = useState(false);
  const [activeDevices, setActiveDevices] = useState(0);
  const [devicesSelected, setDevicesSelected] = useState([]);
  const [params, setParams] = useState({ limit: 100, offset: 0 });
  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
    visible: false,
    clientName: '',
    clientEmail: '',
  });

  const { limit, offset } = params;

  async function refreshTable() {
    await dispatch(DevicesActions.clear_devices_list());

    dispatch(DevicesActions.async_get_all_devices(100, 0));
    dispatch(DevicesActions.enable_load_more(false));
  }

  const handleActivation = (arrayOfDevices, dev_eui, activation) => {
    Modal.confirm({
      title: `Deseja ${activation === 'True' ? 'ativar' : 'desativar'} o dispositivo?`,
      icon: <ExclamationCircleOutlined />,
      content: `${activation === 'True' ? 'A mudança do estado do dispositivo pode gerar cobranças!' : ''}`,

      onOk() {
        dispatch(DevicesActions.async_device_activation({
          listOfDevices: arrayOfDevices.length < 0
            ? [dev_eui] : arrayOfDevices.map((device) => device.dev_eui),
        }, activation));
      },
    });

    setDevicesSelected([]);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: '' });
  };

  const getFilterClients = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Procurar
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Cancelar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: () => <SearchOutlined style={{ color: '#1890ff' }} />,

    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      : ''),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => state.searchInput.select(), 100);
      }
    },

    render: (text) => (state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const menu = ({ block_downlink, dev_eui }) => (
    <Menu>
      {block_downlink
        ? (
          <Menu.Item
            key="desativate-device"
            onClick={() => handleActivation([formValues], dev_eui, 'True')}
          >
            Ativar
          </Menu.Item>
        )
        : (
          <Menu.Item
            key="activate-device"
            onClick={() => handleActivation([formValues], dev_eui, 'False')}
          >
            Desativar
          </Menu.Item>
        )}

      {/* <Menu.Item
        key="delete-device"
        onClick={() => handleRemoveDevice([formValues], dev_eui)}
      >
        Deletar
      </Menu.Item> */}

      <Menu.Item key="edit-device" onClick={() => setupdateModalForm(true)}>
        Editar
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Device ID',
      dataIndex: 'dev_eui',
      key: 'dev_eui',
      width: 80,
      fixed: 'left',
      ...getFilterClients('dev_eui'),
    },

    {
      title: 'Application ID',
      dataIndex: 'app_eui',
      key: 'app_eui',
      width: 80,
      ...getFilterClients('app_eui'),
    },

    {
      title: 'Device address',
      dataIndex: 'dev_addr',
      key: 'dev_addr',
      width: 80,
      ...getFilterClients('dev_addr'),
      render: (dev_addr) => dev_addr || <Tag color="default">{'None'.replace(/["]/g, '')}</Tag>,
    },

    {
      title: 'Status',
      dataIndex: 'block_downlink',
      key: 'status',
      width: 70,
      render: (block_downlink) => (
        <>
          {!block_downlink
            ? <Tag color="green">Ativado</Tag>
            : <Tag color="red">Desativado</Tag>}
        </>
      ),
    },
    // geekblue
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 80,
      ...getFilterClients('tags'),
      render: (tags) => (
        <>
          {tags
            ? tags.map((tag) => (
              <Tag color="geekblue">{tag.replace(/["]/g, '')}</Tag>
            ))
            : <Tag color="default">{'Sem tags'.replace(/["]/g, '')}</Tag>}
        </>
      ),
    },

    {
      title: 'ID do Contrato',
      dataIndex: 'contract_id',
      key: 'contract_id',
      width: 60,
      render: (contract_id) => contract_id || 'Sem contrato associado',
    },

    {
      title: 'Downlinks',
      dataIndex: 'counter_down',
      key: 'counter_down',
      width: 70,
      render: (counter_down) => counter_down || '0',
    },

    {
      title: 'Uplinks',
      dataIndex: 'counter_up',
      key: 'counter_up',
      width: 70,
      render: (counter_up) => counter_up || '0 ',
    },

    {
      title: 'Última ativadade',
      dataIndex: 'last_activity',
      key: 'last_activity',
      width: 80,
      ...getFilterClients('last_activity'),
      render: (...record) => (
        <div className="actions">
          { record[1].last_activity || 'Nenhuma atividade registrada'}

          <Tooltip title="Opções">
            <Dropdown
              overlay={menu(record[1])}
              trigger={['click']}
              onClick={() => setFormValues(record[1])}
            >
              <MoreOutlined style={{ color: '#1890ff', fontSize: '25px' }} />
            </Dropdown>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setDevicesSelected(selectedRows);
    },
  };

  useEffect(() => {
    if (loadMoreDevices) {
      dispatch(DevicesActions.async_get_all_devices(limit, offset));
      setParams({ limit: 100, offset: offset + limit });
      dispatch(DevicesActions.enable_load_more(false));
    }

    if (loadMore) {
      dispatch(ContractActions.async_get_all_contracts(100, 0));
      dispatch(ContractActions.enable_load_more(false));
    }

    // if (tagsList.length < 1) {
    //   dispatch(TagsActions.async_get_all_tags(100, 0));
    // }

    const devices = devicesList.filter((device) => !device.block_downlink);
    setActiveDevices(devices.length);
  }, [devicesList, dispatch, params, loading,
    loadMore, loadMoreDevices, tagsList]);

  return (
    <div className="site-card-border-less-wrapper">
      <Card
        title={`Total de ${devicesList.length} dispositivos registrados e ${activeDevices} ativos`}
        bordered={false}
        style={{ width: '100%' }}
        extra={(
          <Space>
            <Button type="link" onClick={() => setModalVisible(true)}>
              <AppstoreAddOutlined />
              {' '}
              Criar dispositivo

            </Button>

            {/* <Button type="link" onClick={() => setConnectionModal(true)}>
              {' '}
              <ApiOutlined />
              {' '}
              Criar conexão
            </Button>

            <Button type="link" onClick={() => setFilterModal(true)}>
              <FilterOutlined />
              Criar filtro
            </Button> */}

            <Button type="link" onClick={() => setModalsState({ newTagModal: true })}>
              <TagOutlined />
              Criar tag
            </Button>

            <Button type="link" onClick={refreshTable}>
              <ReloadOutlined />
              {' '}
              Atualizar
            </Button>
          </Space>
        )}
      >

        <Table
          columns={columns}
          rowSelection={rowSelection}
          dataSource={devicesList}
          pagination={{ pageSize: 10, position: ['bottomCenter'] }}
          scroll={{ x: 1000 }}
          loading={loading}
          bordered
          footer={() => (
            <>
              <Button
                type="link"
                onClick={() => dispatch(DevicesActions.enable_load_more(true))}
              >
                Carregar mais
              </Button>
            </>
          )}
        />
      </Card>

      <DeviceRegister
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        key="open-modal-2"
      />

      <ManageConnections
        visible={connectionModal}
        onCancel={() => setConnectionModal(false)}
        key="filters-modal"
      />

      <ManageFilters
        visible={filterModal}
        onCancel={() => setFilterModal(false)}
        key="connection-modal"
      />

      <DevUpdateModal
        visible={updateModalForm}
        key={`${formValues.key}-form-updade`}
        deviceInfo={formValues}
        onCancel={() => {
          setupdateModalForm(false);
        }}
      />

      <NewTagModal
        key="new-tag-modal"
        visible={modalsState.newTagModal}
        onCancel={() => setModalsState({ newTagModal: false })}
        client_id={client.id}
      />

      <footer
        className="footer-container"
        style={{ visibility: devicesSelected.length > 0 ? 'visible' : 'hidden' }}
      >
        <div className="footer-text-left">
          <div>
            <p>
              Total de
              {' '}
              <strong>{devicesSelected.length}</strong>
              {' '}
              dispositivo(s) selecionado(s)
            </p>
          </div>

        </div>

        <div className="footer-btns-right">
          <button
            type="button"
            onClick={() => handleActivation(devicesSelected, null, true)}
          >
            <span>Ativar</span>
          </button>

          <button
            type="button"
            onClick={() => handleActivation(devicesSelected, null, false)}
          >
            <span>Desativar</span>
          </button>

          {/* <Button>Desativar</Button> */}

          {u_type === '3' && (
            <button
              type="button"
              onClick={() => handleActivation(devicesSelected, null, false)}
            >
              <DeleteOutlined />
              <span>Deletar</span>
            </button>
          )}
        </div>
      </footer>
    </div>
  );
}

export default ClientDevicesTable;
