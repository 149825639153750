import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import {
  Button,
  Tag,
  Modal,
  Typography,
  Table,
  Space,
  Input,
  Menu,
  Dropdown,
  Tooltip,
} from 'antd';
import {
  SearchOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import UpdateDeviceForm from './updateForm';
import { DevicesActions } from '../../../actions';
import './style.css';

function DevicesManagement() {
  const { Title } = Typography;
  const dispatch = useDispatch();

  const { clientsList } = useSelector((state) => state.ClientsReducer);
  const { devicesList, loadMoreDevices } = useSelector((state) => state.DevicesReducer);
  const { collapsed } = useSelector((state) => state.SiderReducer);

  const [newClass, setNewClass] = useState('');
  const [state, setState] = useState({ searchText: '', searchedColumn: '' });
  const [devicesSelected, setDevicesSelected] = useState([]);
  const [formValues, setFormValues] = useState({});// guarda as informações do device selecionado
  const [visible, setVisible] = useState(false);
  const [activeDevices, setActiveDevices] = useState(0);
  const [params, setParams] = useState({ limit: 100, offset: 0 });

  const { limit, offset } = params;

  function handleActivation(arrayOfDevices, dev_eui, activation) {
    Modal.confirm({
      title: `Confirma a ${activation === 'True' ? 'ativação' : 'desativação'} do dispositivo?`,
      icon: <ExclamationCircleOutlined />,
      content: activation === 'True'
        ? 'A mudança do estado do dispositivo pode gerar cobranças!'
        : '',

      onOk() {
        dispatch(DevicesActions.async_device_activation({
          listOfDevices: arrayOfDevices.length < 0
            ? [dev_eui] : arrayOfDevices.map((device) => device.dev_eui),
        }, activation));
      },
    });
  }

  function handleRemoveDevice(arrayOfDevicesSelected, dev_eui_id) {
    const listOfDevices = arrayOfDevicesSelected.length < 1
      ? [dev_eui_id] : arrayOfDevicesSelected.map((device) => device.dev_eui);
    Modal.confirm({
      title: `Tem certeza que deseja deletar o(s) dispositivo(s) ${dev_eui_id} ?`,
      icon: <ExclamationCircleOutlined />,
      content: '',

      onOk() {
        dispatch(DevicesActions.async_delete_device(listOfDevices));
      },
    });
  }

  async function handleUpdateDevice({
    contract_id,
    tags,
    app_eui,
    dev_class,
    encryption,
    activation,
    counters_size,
    nwkskey,
    dev_addr,
    appskey,
    app_key,
    strict_counter,
    band,
    mode,
  }) {
    const { dev_eui } = formValues;

    dispatch(DevicesActions.async_update_device(dev_eui, {
      contract_id,
      tags,
      app_eui,
      dev_class,
      encryption,
      activation,
      counters_size,
      nwkskey,
      dev_addr,
      appskey,
      app_key,
      strict_counter,
      band,
      adr: { mode },
    }));
    setVisible(false);
  }

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setDevicesSelected(selectedRows);
    },
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: '' });
  };

  const getFilterDevices = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, clearFilters, confirm,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Procurar
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: () => <SearchOutlined style={{ color: '#1890ff' }} />,

    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),

    render: (text) => (state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const menu = ({ dev_eui, block_downlink }) => (
    <Menu>
      {block_downlink
        ? (
          <Menu.Item
            key="desativate-device"
            onClick={() => handleActivation([formValues], dev_eui, 'True')}
          >
            Ativar
          </Menu.Item>
        )
        : (
          <Menu.Item
            key="activate-device"
            onClick={() => handleActivation([formValues], dev_eui, 'False')}
          >
            Desativar
          </Menu.Item>
        )}

      <Menu.Item
        key="delete-device"
        onClick={() => handleRemoveDevice([formValues], dev_eui)}
      >
        Deletar
      </Menu.Item>

      <Menu.Item key="edit-device" onClick={() => setVisible(true)}>
        Editar
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Device EUI',
      dataIndex: 'dev_eui',
      key: 'dev_eui',
      width: 90,
      ...getFilterDevices('dev_eui'),
    },

    {
      title: 'Application ID',
      dataIndex: 'app_eui',
      key: 'app_eui',
      width: 80,
      ...getFilterDevices('app_eui'),
    },

    {
      title: 'Contrato',
      dataIndex: 'contract_id',
      key: 'contract_id',
      width: 70,
      ...getFilterDevices('contract_id'),
      render: (contract_id) => (
        <>
          {contract_id || 'Nenhum contrato associado'}
        </>
      ),
    },

    {
      title: 'Status',
      dataIndex: 'block_downlink',
      key: 'status',
      width: 80,
      render: (block_downlink) => (
        <>
          {!block_downlink
            ? <Tag color="green">Ativado</Tag>
            : <Tag color="red">Desativado</Tag>}
        </>
      ),
    },

    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      width: 100,
      ...getFilterDevices('tags'),
      render: (tags) => (
        <>
          {tags
            ? tags.map((tag) => (
              <Tag color="blue">{tag.replace(/["]/g, '')}</Tag>
            ))
            : <Tag color="default">{'Sem tags'.replace(/["]/g, '')}</Tag>}
        </>
      ),
    },

    {
      title: 'Modo ADR',
      dataIndex: 'adr',
      key: 'adr',
      width: 70,
      render: ({ mode }) => (
        <>
          {mode === 'on'
            ? <Tag color="green">{'Ativado'.replace(/["]/g, '')}</Tag>
            : <Tag color="red">{'Desativado'.replace(/["]/g, '')}</Tag>}

        </>
      ),
    },

    {
      title: 'Downlinks',
      dataIndex: 'counter_down',
      key: 'counter_down',
      width: 70,
      render: (counter_down) => counter_down || 0,
    },

    {
      title: 'Uplinks',
      dataIndex: 'counter_up',
      key: 'counter_up',
      width: 70,
      render: (counter_up) => counter_up || 0,
    },

    {
      title: 'Última ativadade',
      dataIndex: 'last_activity',
      key: 'last_activity',
      width: 120,
      ...getFilterDevices('last_activity'),
      render: (...record) => (
        <div className="actions">
          {record[1].last_activity || 'Nenhuma atividade registrada'}

          <Tooltip title="Opções">
            <Dropdown
              overlay={menu(record[1])}
              trigger={['click']}
              onClick={() => setFormValues(record[1])}
            >
              <MoreOutlined style={{ color: '#1890ff', fontSize: '25px' }} />
            </Dropdown>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (loadMoreDevices) {
      setParams({ limit: 100, offset: offset + limit });
      dispatch(DevicesActions.async_get_all_devices(limit, offset));
      dispatch(DevicesActions.enable_load_more(false));
    }

    const devices = devicesList.filter((device) => !device.block_downlink);
    setActiveDevices(devices.length);

    if (!collapsed) {
      setNewClass('footer-opened');
    } else {
      setNewClass('footer-closed');
    }
  }, [clientsList, devicesList, formValues, state, dispatch, params]);

  return (
    <div className="devices-container">
      <div className="header-container">
        <Title>Gerenciamento de Dispositivos</Title>

        <Button type="primary" key="add-device">
          <Link to="/device-register">
            Novo Dispositivo
            {' '}
            <PlusOutlined />
          </Link>
        </Button>
      </div>

      <div className="actions">
        <h3>
          <strong>
            Total
            {' '}
            {activeDevices}
            {' '}
            de dispositivos ativos
          </strong>
        </h3>
      </div>

      <Table
        columns={columns}
        rowSelection={rowSelection}
        dataSource={devicesList}
        pagination={{ pageSize: 20, position: ['bottomCenter'] }}
        scroll={{ y: 700 }}
        bordered
        loading={!(devicesList.length > 0)}
        footer={() => (
          <>
            <Button
              type="link"
              onClick={() => dispatch(DevicesActions.enable_load_more(true))}
            >
              <PlusOutlined />
              Carregar mais
            </Button>
          </>
        )}
      />

      <UpdateDeviceForm
        visible={visible}
        key={`${formValues.key}-form-updade`}
        devInfo={formValues}
        onUpdate={handleUpdateDevice}
        onCancel={() => {
          setVisible(false);
        }}
        clients={clientsList}
      />

      <footer
        className={newClass}
        style={{ visibility: devicesSelected.length > 0 ? 'visible' : 'hidden' }}
      >
        <div className="footer-text-left">
          <div>
            <p>
              Total de
              {' '}
              <strong>{devicesSelected.length}</strong>
              {' '}
              dispositivos selecionados
            </p>
          </div>

        </div>

        <div className="footer-btns-right">
          <button
            type="button"
            onClick={() => handleActivation(devicesSelected, null, 'True')}
          >
            <span>Ativar</span>
          </button>

          <button
            type="button"
            onClick={() => handleActivation(devicesSelected, null, 'False')}
          >
            <span>Desativar</span>
          </button>

          <button
            type="button"
            onClick={() => handleRemoveDevice(devicesSelected, null)}
          >
            <DeleteOutlined />
            {' '}
            <span>Deletar</span>
          </button>
        </div>
      </footer>
    </div>
  );
}

export default DevicesManagement;
