import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Input,
  Form,
  Button,
  PageHeader,
} from 'antd';
import {
  InfoCircleOutlined,
  LockOutlined,
} from '@ant-design/icons';

import { UsersActions } from '../../../../actions';

function UpdatePassword() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  async function handleUpdatePassword({ password, new_password }) {
    dispatch(UsersActions.async_update_password({ password, new_password }));
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Configurações de segurança"
      />
      <Form
        form={form}
        layout="vertical"
        onFinish={handleUpdatePassword}
      >

        <Form.Item
          label="Senha antiga"
          name="password"
          tooltip={{
            title: 'A senha deve conter pelo menos 6 dígitos, uma letra [a - Z] e um número.',
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: 'Por favor digite a senha!',
            },
            () => ({
              validator(_, value) {
                const pattern = /[A-Za-z]/g;
                const numberPattern = /[0-9]/g;
                if (
                  value.match(pattern).length > 0
                 && value.match(numberPattern).length > 0
                 && value.length >= 6) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('A senha deve conter pelo menos 6 dígitos, uma letra e um número!');
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined />}
            style={{ width: '100%' }}
            placeholder="exemploDeSenha123"
          />
        </Form.Item>

        <Form.Item
          label="Nova antiga"
          name="new_password"
          rules={[
            {
              required: true,
              message: 'Por favor digite a senha!',
            },
            () => ({
              validator(_, value) {
                const pattern = /[A-Za-z]/g;
                const numberPattern = /[0-9]/g;
                if (
                  value.match(pattern).length > 0
                 && value.match(numberPattern).length > 0
                 && value.length >= 6) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('A senha deve conter pelo menos 6 dígitos, uma letra e um número!');
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined />}
            style={{ width: '100%' }}
            placeholder="exemploDeSenha123"
          />
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              Atualizar senha
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
}

export default UpdatePassword;
