import React from 'react';
import { Table, Button } from 'antd';
import {
  PlusOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

function CustomTable({
  columns, dataSource, pageSize, loading,
  rowSelection, onLoadMore,
}) {
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={{ pageSize, position: ['bottomCenter'] }}
      scroll={{ x: '80%' }}
      bordered
      loading={loading}
      rowSelection={rowSelection}
      footer={() => (
        <>
          <Button
            type="link"
            onClick={onLoadMore}
          >
            <PlusOutlined />
            Carregar mais
          </Button>
        </>
      )}
    />
  );
}

CustomTable.propTypes = {
  columns: PropTypes.func.isRequired,
  dataSource: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  rowSelection: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
};

export default CustomTable;
