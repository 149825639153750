import React from 'react';
import {
  Tabs,
  Row,
  Col,
  Card,
  Input,
  Form,
  Button,
  Switch,
  Layout,
  PageHeader,
  Avatar,
} from 'antd';
import {
//   CloseCircleOutlined,
//   EditOutlined,
//   DeleteOutlined,
//   ReloadOutlined,
  UserOutlined,
} from '@ant-design/icons';

import UpdatePassword from './components/updatePassword';
import './style.css';

function UserSettings() {
  const { TabPane } = Tabs;
  const { Content } = Layout;
  const [form] = Form.useForm();

  return (
    <div className="user-container">

      <Card className="test">
        <Tabs defaultActiveKey="1" tabPosition="left">
          <TabPane tab="Configurações do perfil" key="1">

            <Layout>
              <PageHeader
                className="site-page-header"
                title="Configurações básicas"
              />
              <Layout>
                <Row gutter={[150, 150]}>
                  <Col>
                    <Content>
                      <Form
                        form={form}
                        layout="vertical"
                      >
                        <Form.Item
                          label="Nome"
                          name="name"
                          rules={[
                            {
                              required: false,
                              message: 'Por favor adicione o username',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              type: 'email',
                              message: 'Email inválido!',
                            }, {
                              required: false,
                              message: 'Por favor digite seu email!',
                            }]}
                        >
                          <Input placeholder="exemplo@email.com" />
                        </Form.Item>

                        <Form.Item
                          label="Status"
                          name="is_active"
                        >
                          <Switch
                            checkedChildren="Ativado"
                            unCheckedChildren="Desativado"
                            defaultChecked
                          />
                        </Form.Item>

                        <Form.Item shouldUpdate>
                          {() => (
                            <Button
                              type="primary"
                              htmlType="submit"
                              style={{ width: '100%' }}
                            >
                              Atualizar informações
                            </Button>
                          )}
                        </Form.Item>
                      </Form>
                    </Content>
                  </Col>

                  <Col>
                    <Content>
                      <Row>
                        User name
                      </Row>

                      <Row>
                        <Avatar
                          size={{
                            xs: 24,
                            sm: 32,
                            md: 40,
                            lg: 64,
                            xl: 80,
                            xxl: 100,
                          }}
                          style={{
                            backgroundColor: '#f56a00',
                          }}
                          icon={<UserOutlined />}
                        />
                      </Row>

                    </Content>
                  </Col>
                </Row>

              </Layout>
            </Layout>
          </TabPane>

          <TabPane tab="Segurança" key="2">
            <UpdatePassword />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}

export default UserSettings;
