export const ACTION_TYPES = {
  ASYNC_GET_USERS_AND_CLIENTS: 'ASYNC_GET_USERS_AND_CLIENTS',
  ASYNC_ADD_USER: 'ASYNC_ADD_USER',
  ASYNC_USER_ACTIVATION: 'ASYNC_USER_ACTIVATION',
  ASYNC_DELETE_USER: 'ASYNC_DELETE_USER',
  ASYNC_UPDATE_USER: 'ASYNC_UPDATE_USER',
  ASYNC_GET_ALL_USERS: 'ASYNC_GET_ALL_USERS',
  ASYNC_UPDATE_PASSWORD: 'ASYNC_UPDATE_PASSWORD',

  SHOW_USERS: 'SHOW_USERS',
  UPDATE_USER: 'UPDATE_USER',
  ADD_USER: 'ADD_USER',
  DELETE_USER: 'DELETE_USER',
  ENABLE_CREATE_USER_MODAL: 'ENABLE_CREATE_USER_MODAL',
  ENABLE_UPDATE_USER_MODAL: 'ENABLE_UPDATE_USER_MODAL',
  ENABLE_UPDATE_PASSWORD_USER_MODAL: 'ENABLE_UPDATE_PASSWORD_USER_MODAL',
  DISABLE_UPDATE_USER_MODAL: 'DISABLE_UPDATE_USER_MODAL',
  DISABLE_UPDATE_PASSWORD_MODAL: 'DISABLE_UPDATE_PASSWORD_MODAL',
  CLEAR_USERS_LIST: 'CLEAR_USERS_LIST',
  LOAD_MORE_USERS: 'LOAD_MORE_USERS',
};

export const async_update_password = (password_info) => ({
  type: ACTION_TYPES.ASYNC_UPDATE_PASSWORD,
  password_info,
});

export const enable_update_password_modal = (visibility, user_id, user_info) => ({
  type: ACTION_TYPES.ENABLE_UPDATE_PASSWORD_USER_MODAL,
  visibility,
  user_id,
  user_info,
});

export const disable_update_password_modal = (visibility) => ({
  type: ACTION_TYPES.DISABLE_UPDATE_PASSWORD_MODAL,
  visibility,
});

export const async_get_all_users = (limit, offset) => ({
  type: ACTION_TYPES.ASYNC_GET_ALL_USERS,
  limit,
  offset,
});

export const clear_users_list = () => ({
  type: ACTION_TYPES.CLEAR_USERS_LIST,
});

export const async_get_users_and_clients = (
  clients_limit = 100, clients_offset = 0, users_limit = 100, users_offset = 0,
) => ({
  type: ACTION_TYPES.ASYNC_GET_USERS_AND_CLIENTS,
  clients_limit,
  clients_offset,
  users_limit,
  users_offset,
});

export const async_add_user = (user_data) => ({
  type: ACTION_TYPES.ASYNC_ADD_USER,
  user_data,
});

export const async_delete_user = (user_list_id) => ({
  type: ACTION_TYPES.ASYNC_DELETE_USER,
  user_list_id,
});

export const async_update_user = (user_id, user_data) => ({
  type: ACTION_TYPES.ASYNC_UPDATE_USER,
  user_id,
  user_data,
});

export const show_users = (users_data) => ({
  type: ACTION_TYPES.SHOW_USERS,
  users_data,
});

export const load_more_users = (value) => ({
  type: ACTION_TYPES.LOAD_MORE_USERS,
  value,
});

export const enable_create_user_modal = (visibility) => ({
  type: ACTION_TYPES.ENABLE_CREATE_USER_MODAL,
  visibility,
});

export const enable_update_user_modal = (visibility, user_id, user_info) => ({
  type: ACTION_TYPES.ENABLE_UPDATE_USER_MODAL,
  visibility,
  user_id,
  user_info,
});

export const disable_update_user_modal = (visibility) => ({
  type: ACTION_TYPES.DISABLE_UPDATE_USER_MODAL,
  visibility,
});

export const user_activation = (listOfUsers, enabled) => ({
  type: ACTION_TYPES.ASYNC_USER_ACTIVATION,
  listOfUsers,
  enabled,
});

export const update_user = (id, newData) => ({
  type: ACTION_TYPES.UPDATE_USER,
  id,
  newData,
});

export const add_user = (newUser) => ({
  type: ACTION_TYPES.ADD_USER,
  newUser,
});

export const delete_user = (id) => ({
  type: ACTION_TYPES.DELETE_USER,
  id,
});
