export const ACTION_TYPES = {
  ASYNC_ADD_CONTRACT: 'ASYNC_ADD_CONTRACT',
  ASYNC_DELETE_CONTRACT: 'ASYNC_DELETE_CONTRACT',
  ASYNC_UPDATE_CONTRACT: 'ASYNC_UPDATE_CONTRACT',
  ASYNC_GET_ALL_CONTRACTS: 'ASYNC_GET_ALL_CONTRACTS',
  ASYNC_GET_ONE_CONTRACT: 'ASYNC_GET_ONE_CONTRACT',

  UPDATE_CONTRACTS_LIST: 'UPDATE_CONTRACTS_LIST',
  ENABLE_LOAD_MORE: 'ENABLE_LOAD_MORE',
  CLEAR_CONTRACTS_LIST: 'CLEAR_CONTRACTS_LIST',
  UPDATE_CONTRACT: 'UPDATE_CONTRACT',
  ADD_CONTRACT: 'ADD_CONTRACT',
  DELETE_CONTRACT: 'DELETE_CONTRACT',
  ACTIVATE_CONTRACT: 'ACTIVATE_CONTRACT',
};

export const activate_contracts = (contractsIdList, activationStatus) => ({
  type: ACTION_TYPES.ACTIVATE_CONTRACT,
  contractsIdList,
  activationStatus,
});

export const add_contract = (newContract) => ({
  type: ACTION_TYPES.ADD_CONTRACT,
  newContract,
});

export const delete_contract = (id) => ({
  type: ACTION_TYPES.DELETE_CONTRACT,
  id,
});

export const enable_load_more = (value) => ({
  type: ACTION_TYPES.ENABLE_LOAD_MORE,
  value,
});

export const clear_contracts_list = () => ({
  type: ACTION_TYPES.CLEAR_CONTRACTS_LIST,
});

export const async_get_contracts_and_clients = (
  clients_limit = 100, clients_offset = 0, users_limit = 100, users_offset = 0,
) => ({
  type: ACTION_TYPES.ASYNC_GET_USERS_AND_CLIENTS,
  clients_limit,
  clients_offset,
  users_limit,
  users_offset,
});

export const async_add_contract = (contract_data) => ({
  type: ACTION_TYPES.ASYNC_ADD_CONTRACT,
  contract_data,
});

export const async_delete_contract = (listOfContractsID) => ({
  type: ACTION_TYPES.ASYNC_DELETE_CONTRACT,
  listOfContractsID,
});

export const async_update_contract = (contract_id, contract_data_updated) => ({
  type: ACTION_TYPES.ASYNC_UPDATE_CONTRACT,
  contract_id,
  contract_data_updated,
});

export const async_get_all_contracts = (limit, offset) => ({
  type: ACTION_TYPES.ASYNC_GET_ALL_CONTRACTS,
  limit,
  offset,
});

export const async_get_one_contracts = (contract_id) => ({
  type: ACTION_TYPES.ASYNC_GET_ONE_CONTRACT,
  contract_id,
});

export const update_contracts_list = (contracts_data) => ({
  type: ACTION_TYPES.UPDATE_CONTRACTS_LIST,
  contracts_data,
});

export const update_contract = (id, newData) => ({
  type: ACTION_TYPES.UPDATE_CONTRACT,
  id,
  newData,
});
