import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Modal,
  Select,
} from 'antd';
import {
  LockOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { UsersActions } from '../../../../actions';

function CreateUserModal({ visible, onCancel }) {
  const { confirm } = Modal;
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { clientsList } = useSelector((state) => state.ClientsReducer);

  const [userType, setUserType] = useState(false);
  const [displayMessage, setDisplayMessage] = useState({ message: 'Cliente', c_type: 'client' });
  // const [filterProvider, setFilterProvider] = useState([]);
  const name = '';
  const email = '';
  const password = '';
  const user_type = '';

  async function onFinish(values) {
    confirm({
      title: 'Deseja confirmar o registro do usuário?',
      icon: <ExclamationCircleOutlined />,

      onOk() {
        dispatch(UsersActions.async_add_user({
          name: values.name,
          email: values.email,
          password: values.password,
          client_id: values.client_id,
          user_type_id: values.user_type_id,
          is_active: true,
        }));
      },
    });
  }

  function handleChangeUserType(value) {
    if (value === 1) {
      setUserType(true);
    } else {
      setUserType(false);
      setDisplayMessage({
        c_type: value === 2 ? 'client' : 'provider',
        message: value === 2 ? 'Cliente' : 'Fornecedor',
      });
    }
  }

  function handleFilter(input, option) {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  // console.log(clientsList.filter((c) => c.c_type === 'provider'));

  return (
    <Modal
      title="Cadastro de usuário"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      keyboard
      width={500}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          name,
          email,
          password,
          user_type,
        }}
      >

        <Form.Item
          label="Tipo do usuário"
          name="user_type_id"
          rules={[
            {
              required: true,
              message: 'Por favor selecione o cliente!',
            },
          ]}
        >
          <Select size="large" onChange={handleChangeUserType}>
            <Option value={1}>Adminstrador</Option>
            <Option value={2}>Cliente</Option>
            <Option value={3}>Fornecedor</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Nome do usuário"
          name="name"
          rules={[
            {
              required: true,
              message: 'Por favor adicione o username',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<UserOutlined />}
          />
        </Form.Item>

        <Form.Item
          label={`${displayMessage.message}`}
          name="client_id"
          rules={[
            {
              required: !userType,
              message: 'Por favor selecione o cliente!',
            },
          ]}
        >
          <Select
            showSearch
            disabled={userType}
            size="large"
            optionFilterProp="children"
            filterOption={(input, option) => handleFilter(input, option)}
          >
            {clientsList.filter((c) => c.c_type === displayMessage.c_type).map((client) => (
              <Option key={client.key} value={client.id}>
                {client.company_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Email inválido!',
            }, {
              required: true,
              message: 'Por favor digite seu email!',
            }]}
        >
          <Input
            size="large"
            prefix={<UserOutlined />}
            placeholder="exemplo@email.com"
          />
        </Form.Item>

        <Form.Item
          label="Senha do usuário"
          name="password"
          tooltip={{
            title: 'A senha deve conter pelo menos 6 dígitos, uma letra [a - Z] e um número.',
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: 'Por favor digite a senha!',
            },
            () => ({
              validator(_, value) {
                const pattern = /[A-Za-z]/g;
                const numberPattern = /[0-9]/g;
                if (
                  value.match(pattern).length > 0
                 && value.match(numberPattern).length > 0
                 && value.length >= 6) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('A senha deve conter pelo menos 6 dígitos, uma letra e um número!');
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined />}
            placeholder="exemploDeSenha123"
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirme a Senha"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Por favor confirme a senha!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('As senhas estão diferentes!');
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined />}
            placeholder="exemploDeSenha123"
          />
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%' }}
            >
              Registrar
            </Button>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

CreateUserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateUserModal;
