import { ACTION_TYPES } from '../../actions/tagActions';

const INITIAL_STATE = {
  tagsList: [],
  tag: {
    client_id: Number,
    name: String,
  },

  loadMoreTags: true,
  createTagModalVisibility: false,
};

const TagsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SAVE_TAGS:
      return { ...state, tagsList: [...state.tagsList, ...action.listOfTags] };

    case ACTION_TYPES.CLEAR_TAGS_LIST:
      return { ...state, tagsList: [] };

    case ACTION_TYPES.LOAD_MORE_TAGS:
      return { ...state, loadMoreTags: action.value };

    case ACTION_TYPES.ADD_TAG:
      return {
        ...state,
        tagsList: [...state.tagsList, action.newTagData],
      };

    case ACTION_TYPES.REFRESH_TAGS_TABLE:
      return { ...state, tagsList: [] };

    case ACTION_TYPES.OPEN_CREATE_TAG_MODAL:
      return { ...state, createTagModalVisibility: action.visibility };

    default:
      return state;
  }
};

export default TagsReducer;
