import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Input,
  Form,
  Col,
  Row,
  Button,
  Select,
  Modal,
  InputNumber,
} from 'antd';
import {
  CloseCircleOutlined,
} from '@ant-design/icons';

import { DevicesActions } from '../../../actions';
import EditableTagGroup from '../devices/tagsUpdate';

function DevUpdateModal({ deviceInfo, visible, onCancel }) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { contractsList } = useSelector((state) => state.ContractsReducer);

  const {
    dev_eui,
    tags,
    app_eui,
    dev_class,
    encryption,
    activation,
    counters_size,
    nwkskey,
    dev_addr,
    appskey,
    app_key,
    strict_counter,
    band,
    adr,
    rx1,
  } = deviceInfo;
  const { contract_id } = deviceInfo || 'Sem contrato';
  const { mode } = adr || 'on';
  const { delay } = rx1 || '';
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [listOfTags, setListOfTags] = useState([]);
  const [activeABP, setActiveABP] = useState(activation);
  const [encryptNS, setEncryptNS] = useState(encryption);

  async function handleUpdateDevice(values) {
    setConfirmLoading(true);

    form
      .validateFields()
      .then(() => {
        form.resetFields();

        dispatch(DevicesActions.async_update_device(dev_eui, {
          contract_id: values.contract_id,
          tags: listOfTags,
          app_eui: values.app_eui,
          dev_class: values.dev_class,
          encryption: values.encryption,
          activation: values.activation,
          counters_size: values.counters_size,
          nwkskey: values.nwkskey,
          dev_addr: values.dev_addr,
          appskey: values.appskey,
          app_key: values.app_key,
          strict_counter,
          band: values.band,
          adr: { mode: values.mode },
          rx1: { delay: values.delay },
        }));
      });

    setConfirmLoading(false);
  }

  function handleUpdateTags(newTags) {
    setListOfTags(newTags);
  }

  useEffect(() => {}, [listOfTags]);

  return (
    <Modal
      title={`Edição do dispositivo ${dev_eui}`}
      key={dev_eui}
      visible={visible}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      keyboard
      width={600}
      footer={null}
      // footer={[
      //   <Button onClick={onCancel}>Cancelar</Button>,
      //   <Button
      //     type="primary"
      //     onClick={handleUpdateDevice}
      //   >
      //     Salvar
      //   </Button>,
      // ]}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ textAlign: 'left', padding: 30, width: '100%' }}
        onFinish={handleUpdateDevice}
        initialValues={{
          contract_id,
          dev_eui,
          tags,
          app_eui,
          dev_class,
          encryption,
          activation,
          counters_size,
          nwkskey,
          dev_addr,
          appskey,
          app_key,
          strict_counter,
          band,
          mode,
          delay,
        }}
      >

        <Row gutter={16}>
          <Form.Item
            label="Contrato"
            name="contract_id"
            rules={[
              {
                required: true,
                message: 'Por favor selecione um contrato!',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Selecione o Contrato"
              optionFilterProp="children"
            >
              {contractsList.map((contract) => (
                <Option key={contract.id} value={contract.id}>
                  {contract.id}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="App EUI"
              name="app_eui"
              rules={[{
                required: true,
                message: 'Por favor, insira o cpf ou CNPJ！',
              }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Classe do dispositivo"
              name="dev_class"
              rules={[{
                required: true,
                message: 'Insira a ！',
              }]}
            >
              <Select placeholder="Dev">
                <Option key="A" value="A">A</Option>
                <Option key="C" value="C">C</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Encriptação"
              name="encryption"
              rules={[{
                required: true,
                message: 'Por favor selecione a encriptação!',
              }]}
            >
              <Select
                placeholder="Encryption"
                onChange={(value) => setEncryptNS(value)}
              >
                <Option key="APP" value="APP">APP</Option>
                <Option key="NS" value="NS">NS</Option>
              </Select>
            </Form.Item>

          </Col>

          <Col span={12}>
            <Form.Item
              label="Modo de ativação"
              name="activation"
              rules={[{
                required: true,
                message: 'Selecione o modo de ativação',
              }]}
            >
              <Select
                onChange={(value) => setActiveABP(value)}
              >
                <Option key="ABP" value="ABP">ABP</Option>
                <Option key="OTAA" value="OTAA">OTAA</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col Col span={12}>
            <Form.Item
              label="Network session Key"
              name="nwkskey"
              rules={[{
                required: activeABP === 'ABP',
                message: 'Por favor adicione o NwsKey!',
              }]}
            >
              <Input
                placeholder="90bdba643fc501d896e10b2ba83f8849"
                disabled={activeABP === 'OTAA'}
                maxLength="32"
              />
            </Form.Item>
          </Col>

          <Col Col span={12}>
            <Form.Item
              label="Dev Address"
              name="dev_addr"
              rules={[
                {
                  required: activeABP === 'ABP',
                  message: 'Por favor adicione o Device Address!',
                },
              ]}
            >
              <Input
                placeholder="Dev Address"
                disabled={activeABP === 'OTAA'}
                maxLength="8"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="App session KEY"
              name="appskey"
              rules={[
                {
                  required: (encryptNS === 'NS' && activeABP === 'ABP'),
                  message: 'Por favor adicione o Apps Key!',
                },
              ]}
            >
              <Input
                placeholder="Apps KEY"
                maxLength="32"
                disabled={!(encryptNS === 'NS' && activeABP === 'ABP')}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="App Key"
              name="app_key"
              rules={[
                {
                  required: false,
                  message: 'Por favor adicione o App Key!',
                },
              ]}
            >
              <Input
                placeholder="App KEY"
                maxLength="32"
                disabled={!(encryptNS === 'NS' && activeABP === 'OTAA')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Counter Size"
              name="counters_size"
              rules={[
                {
                  required: true,
                  message: 'Por favor selecione o Counter Size!',
                },
              ]}
            >
              <Select placeholder="CsetEncryptNSounter">
                <Option key="counter-2" value={2}>2</Option>
                <Option key="counter-4" value={4}>4</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Modo ADR"
              name="mode"
            >
              <Select style={{ width: '220px' }}>
                <Option key="on-adr-mode" value="on">Ativado</Option>
                <Option key="off-adr-mode" value="off">Desativado</Option>
                {/* <Option key="static-adr-mode" value="static">Estático</Option> */}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Banda" name="band">
              <Select>
                <Option value="LA915-928A">LA915-928A</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Rx1"
              name="delay"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <InputNumber min={0} max={100000} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Item
              label="Tags"
              name="tags"
              rules={[
                {
                  required: false,
                  message: 'Crie uma ou mais tags!',
                  type: 'array',
                },
              ]}
            >
              <EditableTagGroup
                newtags={handleUpdateTags}
                oldTags={tags}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item>
              <Button
                onClick={onCancel}
                type="default"
                style={{ width: '100%' }}
              >
                Cancelar
              </Button>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Salvar
              </Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Modal>
  );
}

DevUpdateModal.propTypes = {
  deviceInfo: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DevUpdateModal;
