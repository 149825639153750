import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

function TableFooter({
  className, entitySelected, entityName,
}) {
  return (
    <footer
      className={className}
      style={{
        visibility: entitySelected.length > 0 ? 'visible' : 'hidden',
      }}
    >
      <div className="footer-text-left">
        <div>
          <p>
            Total de
            {' '}
            <strong>{entitySelected.length}</strong>
            {' '}
            {entityName}
            {' '}
            selecionados
          </p>
        </div>

        {/* <div className="footer-btns-right">
          <button
            type="button"
            onClick={() => onActivation}
          >
            <span>Ativar</span>
          </button>

          <button
            type="button"
            onClick={() => onDeActivation}
          >
            <span>Desativar</span>
          </button>
        </div> */}

      </div>
    </footer>
  );
}

TableFooter.propTypes = {
  entitySelected: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  className: PropTypes.func.isRequired,
//   onDeActivation: PropTypes.func.isRequired,
//   onActivation: PropTypes.func.isRequired,
};

export default TableFooter;
