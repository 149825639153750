import React from 'react';
import { Provider } from 'react-redux';

import Routes from './routes';
import store from './store';
import { GlobalContainer } from './styles/global';

function App() {
  return (
    <GlobalContainer>
      <Provider store={store}>
        <Routes />
      </Provider>
    </GlobalContainer>
  );
}

export default App;
