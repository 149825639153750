export const ACTION_TYPES = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS', // logout síncrono
  SIGN_IN_REQUEST_LOGIN: 'SIGN_IN_REQUEST_LOGIN',
};

export const login_success = (token, user_type) => ({
  type: ACTION_TYPES.LOGIN_SUCCESS,
  token,
  user_type,
});

export const login_failure = () => ({
  type: ACTION_TYPES.LOGIN_FAILURE,
});

export const logout = () => ({
  type: ACTION_TYPES.LOGOUT,
});

export const sign_in_request = ({ email, password }) => ({
  type: ACTION_TYPES.SIGN_IN_REQUEST,
  email,
  password,
});

export const logout_success = () => ({
  type: ACTION_TYPES.LOGOUT_SUCCESS,
});

export const sign_in_request_loading = () => ({
  type: ACTION_TYPES.SIGN_IN_REQUEST_LOGIN,
});
