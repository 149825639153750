import { notification } from 'antd';
import { put } from 'redux-saga/effects';

import api from '../../services/api';
import { ContractActions } from '../../actions';

export function* getContractById(id) {
  const { data } = yield api.get(`/contracts/${id}`).then((response) => response);
  data.key = `${id}`;
  return data;
}

export function* createContracts({ contract_data }) {
  try {
    const contract = yield api.post('/contracts/create-contract', contract_data)
      .then((response) => response);
    notification.success({ message: 'Contrado registrado.' });

    const newContract = yield getContractById(contract.data.id);
    yield put(ContractActions.add_contract(newContract));
  } catch (error) {
    notification.error({
      message: 'Erro no registro!',
      description: 'Ocorreu um erro ao registrar o contrato. Revise as informações e tente novamente.',
    });
  }
}

export function* deleteContract({ listOfContractsID }) {
  try {
    yield Promise.all(
      listOfContractsID.map((contract) => api.delete(`/contracts/${contract}`)),
    );

    if (listOfContractsID.length >= 1) {
      notification.success({ message: 'Contrato deletado.' });
    } else notification.success({ message: 'Contratos deletados.' });
  } catch (error) {
    notification.error({
      message: 'Erro na remoção do contrato!',
      description: `Ocorreu um erro no processo, verifique sua conexão.${error}`,
    });
  }

  yield put(ContractActions.delete_contract(listOfContractsID[0]));
}

export function* updateContract({ contract_id, contract_data_updated }) {
  try {
    yield api.patch(`/contracts/${contract_id}`, contract_data_updated);
    notification.success({ message: 'Contrato editado.' });
  } catch (error) {
    notification.error({
      message: 'Erro na edição do contrato!',
      description: 'Verifique os dados preenchidos ou sua conexão',
    });
  }

  const contractUpdated = yield getContractById(contract_id);
  yield put(ContractActions.update_contract(contract_id, contractUpdated));
}

export function* getOneContract({ contract_id }) {
  try {
    yield api.get(`/contracts/${contract_id}`).then(
      notification.success({ message: 'Contrato encontrado.' }),
    );
  } catch (error) {
    notification.error({
      message: 'Falha na pesquisa do contrato!',
      description: 'Contrato inexistente ou falha na conexão.',
    });
  }
}

export function* getAllContracts({ limit, offset }) {
  try {
    const { data } = yield api.get('/contracts', { params: { limit, offset } })
      .then((response) => response);

    if (data.length > 0) {
      data.map((i) => Object.assign(i, { key: `${i.id}` }));
    }

    yield put(ContractActions.update_contracts_list(data));
  } catch (error) {
    notification.error({
      message: 'Sua sessão expirou',
      description: 'Faça o login novamente',
    });
  }
}
