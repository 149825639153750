import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Tabs,
  List,
  Card,
  Input,
  Divider,
  Space,
  Form,
  Button,
  Switch,
  Tag,
  Select,
  // Descriptions,
} from 'antd';
import {
  CloseCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
  LockOutlined,
} from '@ant-design/icons';

import { filterData } from './mockData';

const ManageFilters = ({ visible, onCancel }) => {
  // Componentes do antd
  const { TabPane } = Tabs;
  const { CheckableTag } = Tag;
  const { Option } = Select;

  // Variáveis de controle
  const [tagsSelected, setTagsSelected] = useState([]);
  const [optionsSelected, setOptionsSelected] = useState([]);
  const tagsData = ['json_request', 'uplink', 'downlink', 'error', 'warning', 'info', 'location', 'status_response'];
  const AditionalOptions = ['Duplicates', 'Radio data', 'Lora data'];

  const onSearch = () => {};

  function handleTagChange(tag, checked) {
    const nextSelectedTags = checked
      ? [...tagsSelected, tag] : tagsSelected.filter((t) => t !== tag);
    setTagsSelected(nextSelectedTags);
  }

  function handleAditionalOptionChange(tag, checked) {
    const nextSelectedTags = checked
      ? [...optionsSelected, tag] : optionsSelected.filter((t) => t !== tag);
    setOptionsSelected(nextSelectedTags);
  }

  function onChange() {}// checked

  function handleChange() {} // Mudamça do estado de limite de dispositivos

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      width={1000}
      bodyStyle={{ height: '600px' }}
      footer={null}
    >
      <Tabs defaultActiveKey="1" tabPosition="left">
        <TabPane tab="Criar filtro" key="1">

          <Form layout="vertical">
            <Form.Item label="Descrição" name="description">
              <Space>
                <Input style={{ width: '100%' }} />
                <span>
                  <LockOutlined />
                  habilitar
                  {' '}
                </span>
                <Switch onChange={onChange} />
              </Space>
            </Form.Item>

            <h2>Filtrar por</h2>

            <Form.Item
              label="Tags dos dispositivos"
              name="device_tags"
            >
              <Input style={{ width: '50%' }} />
            </Form.Item>

            <Form.Item
              label="EUI dos dispositivos"
              name="devs_eui"
            >
              <Input style={{ width: '50%' }} />
            </Form.Item>

            <Form.Item
              label="EUI da aplicação"
              name="apps_eui"
            >
              <Input style={{ width: '50%' }} maxLength={24} />
            </Form.Item>

            <Form.Item
              label="Gateways"
              name="gateways"
            >
              <Input style={{ width: '50%' }} maxLength={24} />
            </Form.Item>

            <h2>Tipos de mensagens</h2>
            <Form.Item>
              {tagsData.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={tagsSelected.indexOf(tag) > -1}
                  onChange={(checked) => handleTagChange(tag, checked)}
                >
                  {tag}
                </CheckableTag>
              ))}
            </Form.Item>

            <h2>Opções</h2>
            <Form.Item>
              {AditionalOptions.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={optionsSelected.indexOf(tag) > -1}
                  onChange={(checked) => handleAditionalOptionChange(tag, checked)}
                >
                  {tag}
                </CheckableTag>
              ))}
            </Form.Item>

            <h2>Limite de dispositivos</h2>
            <Form.Item>
              <Select style={{ width: '25%' }} onChange={handleChange}>
                <Option key="opt1" value="20">20</Option>
                <Option key="opt2" value="50">50</Option>
                <Option key="opt3" value="100">100</Option>
                <Option key="opt4" value="500">500</Option>
                <Option key="opt5" value="unlimited">Sem Limite</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Salvar filtro
              </Button>
            </Form.Item>

          </Form>
        </TabPane>
        <TabPane tab="Filtros existentes" key="2">
          <Input.Search
            placeholder="ID do filtro"
            allowClear
            onSearch={onSearch}
            style={{ width: '80%' }}
          />
          <Divider />
          <List
            dataSource={filterData}
            renderItem={(item) => (
              <List.Item>
                <Card
                  title={item.id}
                  actions={[
                    <EditOutlined />,
                    <DeleteOutlined />,
                    <ReloadOutlined />,
                  ]}
                  style={{ width: '100%' }}
                >
                  <span>
                    <strong>Tags: </strong>
                    {item.devices_tag.map((device) => (
                      <Tag color="cyan">{device}</Tag>
                    ))}
                  </span>
                  <br />
                  <span>
                    <strong>Dev EUIs: </strong>
                    {item.dev_eui.map((device) => (
                      <Tag color="blue">{device}</Tag>
                    ))}
                  </span>
                  <br />
                  <span>
                    <strong>App EUIs: </strong>
                    {item.app_eui.map((device) => (
                      <Tag color="orange">{device}</Tag>
                    ))}
                  </span>
                  <br />
                  <span>
                    <strong>Gateways: </strong>
                    {item.gateways.map((device) => (
                      <Tag color="green">{device}</Tag>
                    ))}
                  </span>
                  <br />
                  <span>
                    <strong>Mensagens: </strong>
                    {item.message_types.map((device) => (
                      <Tag color="volcano">{device}</Tag>
                    ))}
                  </span>
                  <br />
                  <span>
                    <strong>Opções: </strong>
                    {item.options.map((device) => (
                      <Tag color="gold">{device}</Tag>
                    ))}
                  </span>
                  <br />
                  <span>
                    <strong>Limite: </strong>
                    {item.limit.map((device) => (
                      <Tag color="red">{device}</Tag>
                    ))}
                  </span>
                </Card>
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

ManageFilters.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ManageFilters;
