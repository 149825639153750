import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import {
  Button,
  Space,
  Input,
  Card,
} from 'antd';
import {
  SearchOutlined,
  ReloadOutlined,
  TagOutlined,
} from '@ant-design/icons';

import { TagsActions } from '../../../actions';
import { CustomTable, TableFooter } from '../../../components';

function TagsTable() {
  const dispatch = useDispatch();
  const { collapsed } = useSelector((state) => state.SiderReducer);
  const { tagsList, loadMoreTags } = useSelector((state) => state.TagsReducer);
  const { clientsList } = useSelector((state) => state.ClientsReducer);

  const [newClass, setNewClass] = useState('');
  const [userSelected, setUserSelected] = useState([]);
  const [state, setState] = useState({
    searchText: '',
    searchedColumn: '',
    visible: false,
    clientName: '',
    clientEmail: '',
  });
  const [params, setParams] = useState({ limit: 100, offset: 0 });
  const { limit, offset } = params;

  async function refreshTable() {
    await dispatch(TagsActions.refresh_table());

    dispatch(TagsActions.async_get_all_tags(100, 0));
    dispatch(TagsActions.load_more_tags(false));
  }

  const getClientNameById = (client_id) => {
    const { company_name } = clientsList.filter((client) => client_id === client.id)[0] || 'Sem cliente associado';
    return company_name || 'Sem cliente associado';
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: '' });
  };

  const getFilterClients = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Procurar
          </Button>

          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Cancelar
          </Button>
        </Space>
      </div>
    ),

    filterIcon: () => <SearchOutlined style={{ color: '#1890ff' }} />,

    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
      : ''),

    onFilterDropdownVisibleChange: (visible) => visible,

    render: (text) => (state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const columns = [
    {
      title: 'Tag',
      dataIndex: 'name',
      key: 'name',
      width: 50,
      ...getFilterClients('name'),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      ...getFilterClients('id'),
    },

    {
      title: 'Cliente',
      dataIndex: 'client_id',
      key: 'company_name',
      width: 80,
      // ...getFilterClients('client_id'),
      render: (client_id) => getClientNameById(client_id),
    },

  ];

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setUserSelected(selectedRows);
    },
  };

  useEffect(() => {
    if (loadMoreTags) {
      setParams({ limit: 100, offset: offset + limit });
      dispatch(TagsActions.async_get_all_tags(limit, offset));
      dispatch(TagsActions.load_more_tags(false));
    }

    if (!collapsed) {
      setNewClass('footer-opened');
    } else {
      setNewClass('footer-closed');
    }
  }, [dispatch, loadMoreTags, params, tagsList]);

  return (
    <div className="site-card-border-less-wrapper">
      <Card
        bordered={false}
        style={{ width: '100%' }}
        extra={(
          <Space>
            <Button type="link" onClick={() => dispatch(TagsActions.open_create_tag_modal(true))}>
              <TagOutlined />
              {' '}
              Nova tag
            </Button>

            <Button type="link" onClick={refreshTable}>
              <ReloadOutlined />
              {' '}
              Atualizar
            </Button>
          </Space>
          )}
      >

        <CustomTable
          columns={columns}
          rowSelection={rowSelection}
          dataSource={tagsList}
          pageSize={10}
          bordered
          loading={!(tagsList.length > 0)}
          onLoadMore={() => dispatch(TagsActions.load_more_tags(true))}
        />
      </Card>

      <TableFooter
        className={newClass}
        entitySelected={userSelected}
        entityName="Tags"
      />
    </div>
  );
}

export default TagsTable;
