export const ACTION_TYPES = {
  GET_ONE_CLIENT: 'GET_ONE_CLIENT',
  SHOW_CLIENTS: 'SHOW_CLIENTS',
  ADD_CLIENT: 'ADD_CLIENT',
  DELETE_CLIENT: 'DELETE_CLIENT',
  UPDATE_CLIENT: 'UPDATE_CLIENT',
  SHOW_ONE_CLIENT: 'SHOW_ONE_CLIENT',
  CLEAR_CLIENT_LIST: 'CLEAR_CLIENT_LIST',
  LOAD_MORE_CLIENTS: 'LOAD_MORE_CLIENTS',

  // Ações assíncronas
  ASYNC_GET_CLIENT_DEVICES: 'ASYNC_GET_CLIENT_DEVICES',
  ASYNC_GET_CLIENTS: 'ASYNC_GET_CLIENTS',
  ASYNC_GET_ONE_CLIENT: 'ASYNC_GET_ONE_CLIENT',
  ASYNC_CLIENT_ACTIVATION: 'ASYNC_CLIENT_ACTIVATION',
  CLIENT_ACTIVATION: 'CLIENT_ACTIVATION',
  ASYNC_REGISTER_USER: 'ASYNC_REGISTER_USER',
  ASYNC_ADD_CLIENT: 'ASYNC_ADD_CLIENT',
  ASYNC_DELETE_CLIENTS: 'ASYNC_DELETE_CLIENTS',
  ASYNC_UPDATE_CLIENTS: 'ASYNC_UPDATE_CLIENTS',
  ASYNC_GET_PROVIDERS_CLIENT: 'ASYNC_GET_PROVIDERS_CLIENT',

  // requisição múltipla
  ASYNC_GET_CLIENTS_AND_USERS: 'ASYNC_GET_CLIENTS_AND_USERS',
};

export const enable_load_more_clients = (value) => ({
  type: ACTION_TYPES.LOAD_MORE_CLIENTS,
  value,
});

/* AÇÕES SÍNCRONAS */
export const show_clients = (payload) => ({
  type: ACTION_TYPES.SHOW_CLIENTS,
  payload,
});

export const clear_client_list = () => ({
  type: ACTION_TYPES.CLEAR_CLIENT_LIST,
});

export const add_client = (newClient) => ({
  type: ACTION_TYPES.ADD_CLIENT,
  newClient,
});

export const client_activation = (text) => ({
  type: ACTION_TYPES.CLIENT_ACTIVATION,
  payload: text,
});

export const delete_client = (id) => ({
  type: ACTION_TYPES.DELETE_CLIENT,
  id,
});

export const update_client = (id, newData) => ({
  type: ACTION_TYPES.UPDATE_CLIENT,
  id,
  newData,
});

export const async_get_providers_client = (limit, offset) => ({
  type: ACTION_TYPES.ASYNC_GET_PROVIDERS_CLIENT,
  limit,
  offset,
});

// Ação para atualizar a informação de apenas 1 cliente
export const show_one_client = (clientInfo) => ({
  type: ACTION_TYPES.SHOW_ONE_CLIENT,
  clientInfo,
});

// atualiza o reducer com a informação de 1 client/provider
export const get_one_client = (text) => ({
  type: ACTION_TYPES.GET_ONE_CLIENT,
  payload: text,
});

/* AÇÕES ASSÍNCRONAS */
export const async_add_client = (data) => ({
  type: ACTION_TYPES.ASYNC_ADD_CLIENT,
  data,
});

export const async_delete_client = (clients) => ({
  type: ACTION_TYPES.ASYNC_DELETE_CLIENTS,
  data: clients,
});

export const async_update_client = (client_id, clientNewData) => ({
  type: ACTION_TYPES.ASYNC_UPDATE_CLIENTS,
  newData: clientNewData,
  id: client_id,
});

export const async_client_activation = (listOfClients, activation) => ({
  type: ACTION_TYPES.ASYNC_CLIENT_ACTIVATION,
  activation,
  data: listOfClients,
});

export const async_register_user = (userInfo) => ({
  type: ACTION_TYPES.ASYNC_REGISTER_USER,
  payload: userInfo,
});

export const async_get_clients = (limit, offset) => ({
  type: ACTION_TYPES.ASYNC_GET_CLIENTS,
  limit,
  offset,
});

export const async_get_one_client = (limit, offset) => ({
  type: ACTION_TYPES.ASYNC_GET_ONE_CLIENT,
  limit,
  offset,
});

export const async_get_clients_and_users = (
  clients_limit,
  clients_offset,
  users_limit,
  users_offset,
) => ({
  type: ACTION_TYPES.ASYNC_GET_CLIENTS_AND_USERS,
  clients_limit,
  clients_offset,
  users_limit,
  users_offset,
});

export const async_get_client_devices = (client_id) => ({
  type: ACTION_TYPES.ASYNC_GET_CLIENT_DEVICES,
  client_id,
});
