import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Tabs,
  List,
  Card,
  Input,
  Divider,
  Select,
  Form,
  Button,
  Tag,
} from 'antd';
import {
  CloseCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

import { connectionData } from './mockData';

const ManageConnections = ({ visible, onCancel }) => {
  // Componentes do antd
  const { TabPane } = Tabs;
  const { Option } = Select;
  // Variáveis de controle
  const [connectionType, setConnectionType] = useState('MQTT');

  const onSearch = () => {};

  const handleChange = (value) => {
    setConnectionType(value);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
      width={1000}
      bodyStyle={{ height: '600px' }}
      footer={null}
    >
      <Tabs defaultActiveKey="1" tabPosition="left">
        <TabPane tab="Criar conexão" key="1">

          <h2>Tipo da conexão</h2>
          <div className="select-connection-type">
            <Select style={{ width: '25%' }} onChange={handleChange}>
              <Option key="opt1" value="MQTT">MQTT</Option>
              <Option key="opt2" value="HTTP">HTTP</Option>
              <Option key="opt3" value="Web Sockets">Web Sockets</Option>
            </Select>
          </div>

          <div className="connection-form">
            {(connectionType === 'HTTP' || connectionType === 'Web Sockets')
              ? (
                <Form layout="vertical">
                  <Form.Item label="Filter" name="filter">
                    <Input style={{ width: '50%' }} placeholder="87405988932b4db9b032" />
                  </Form.Item>

                  <Form.Item
                    label="URL da apicação"
                    name="app_url"
                    tooltip="Você pode anexar o link da sua aplicação."
                  >
                    <Input style={{ width: '50%' }} placeholder="https://exemplo.com..." />
                  </Form.Item>

                  <Form.Item
                    label="Description"
                    name="description"
                    requiredMark="optional"
                  >
                    <Input style={{ width: '50%' }} />
                  </Form.Item>

                  <Form.Item
                    label="Authorization header"
                    name="authorization_header"
                    requiredMark="optional"
                  >
                    <Input style={{ width: '50%' }} placeholder="Base YWxhZGRpbjpvcGVuc2zYW1j" maxLength={24} />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Salvar conexão
                    </Button>
                  </Form.Item>

                </Form>
              )
              : (
                <Form layout="vertical">
                  <Form.Item
                    label="Description"
                    name="description"
                    requiredMark="optional"
                  >
                    <Input style={{ width: '50%' }} />
                  </Form.Item>

                  <Form.Item
                    label="Network Server publish topic"
                    name="network_server_publish"
                  >
                    <Input style={{ width: '50%' }} />
                  </Form.Item>

                  <Form.Item
                    label="URI"
                    name="uri"
                  >
                    <Input style={{ width: '50%' }} placeholder="mqtt://user:password@exemplo.com:1883" />
                  </Form.Item>

                  <Form.Item
                    label="Filter"
                    name="filter"
                  >
                    <Input
                      style={{ width: '50%' }}
                      placeholder="87405988932b4db9b032"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Network Server subscribe topic"
                    name="network_server_subscribe"
                    requiredMark="optional"
                  >
                    <Input style={{ width: '50%' }} />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Salvar conexão
                    </Button>
                  </Form.Item>
                </Form>
              )}
          </div>

        </TabPane>

        <TabPane tab="Conexões existentes" key="2">

          <Input.Search
            placeholder="ID da conexão"
            allowClear
            onSearch={onSearch}
            style={{ width: '80%' }}
          />
          <Divider />
          <List
            grid={{
              gutter: 16,
              column: 2,
            }}
            dataSource={connectionData}
            renderItem={(item) => (
              <List.Item>
                <Card
                  title={item.id}
                  actions={[
                    <EditOutlined />,
                    <DeleteOutlined />,
                    <ReloadOutlined />,
                  ]}
                >
                  <span>
                    <strong>Conexão: </strong>
                    <Tag color="cyan">{item.connection_type}</Tag>
                  </span>
                  <br />

                  <span>
                    <strong>Filtro: </strong>
                    {item.filter}
                  </span>
                  <br />

                  <span>
                    <strong>URL da apicação: </strong>
                    <span href="#">{item.app_url}</span>
                  </span>
                  <br />

                  <span>
                    <strong>Descrição: </strong>
                    {item.description}
                  </span>
                  <br />

                  <span>
                    <strong>Cabeçalho autenticado: </strong>
                    {item.authorization_header}
                  </span>
                  <br />
                </Card>
              </List.Item>
            )}
          />

        </TabPane>
      </Tabs>
    </Modal>
  );
};

ManageConnections.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ManageConnections;
