import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import store from '../store';

const GuestRoute = ({ component: Component, ...rest }) => (
  (store.getState().AuthReducer.u_type === '1'
    ? (
      <Route
        {...rest}
        render={(props) => (!(store.getState().AuthReducer.token
          && (store.getState().AuthReducer.u_type === '1'
           || store.getState().AuthReducer.u_type === 1))
          ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: '/', state: { from: props.location } }}
            />
          ))}
      />
    )
    : (
      <Route
        {...rest}
        render={(props) => (!(store.getState().AuthReducer.token
          && (store.getState().AuthReducer.u_type === '2'
          || store.getState().AuthReducer.u_type === 2))
          ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: '/home-view', state: { from: props.location } }}
            />
          ))}
      />
    )
  )

);

GuestRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default GuestRoute;
