import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import store from '../store';

const ClientPrivateRoute = ({ component: Component, ...rest }) => {
  function checkCredentials() { // Mudar a lógica de verificação
    if (store.getState().AuthReducer.u_type === 2 || store.getState().AuthReducer.u_type === '2'
    || store.getState().AuthReducer.u_type === 3 || store.getState().AuthReducer.u_type === '3') {
      return true;
    }
    return false;
  }

  return (
    <Route
      {...rest}
      render={(props) => ((store.getState().AuthReducer.token && checkCredentials)
        ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        ))}
    />
  );
};

ClientPrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ClientPrivateRoute;
