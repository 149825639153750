import { ACTION_TYPES } from '../../actions/devicesActions';

const INITIAL_STATE = {
  devicesList: [],
  loadMoreDevices: true,
  migrateDevicesList: [],
  targetKeys: [],
  loading: true,

  device: {
    dev_eui_id: String, // "cb105012c807695l" input normal(hexa)
    tags: ['Default tag'],
    activation: 'ABP', // ABP OU OTAA
    adr: {
      tx_power: null, // null
      datarate: null, // null
      mode: 'on', // ON / OFF / STATIC
    },
    contract_id: Number,
    app_eui: '494b284ab1eca29b', // input normal(hexa)
    app_key: null, // "ok1n31203123981jknldmd", // input normal(hexa)
    appskey: null, // "1c8a76070844d1d7a5e5e29f97f28686",//SE NS = TRUE
    band: 'LA915-928A', // "LA915-928A" padrão latina americana (915-928mhz)
    block_downlink: 'True', // padrao "true"
    block_uplink: 'True', // padrao "true"
    counter_down: Number, // estado inicial 0
    counter_up: Number, // estado inicial 0
    counters_size: Number, // 4 ou 2
    dev_addr: '5a27f147', // SE ABP = TRUE // input normal(hexa)
    dev_class: 'A', // 'A' ou 'C'
    encryption: 'APP', // APP OU NS
    // geolocation: null, // NÃO OBRIGATORIO
    // last_activity: null, // padrão "null"
    // last_join: null, // padrão "null" //fora da interface
    nwkskey: '95196a50c94397b8b67c2581b6671d04', // (NETWORK S KEY) SE ABP = TRUE //input normal(hexa)
    device_type: 'test',
    rx1: { // NÃO OBRIGATORIO
      // "rxteste": "adasds",
      // "rarar": "asd13"
      delay: 1,
    },
    strict_counter: false,
  },

  createDevice: {
    tags: ['Default tag'],
    activation: 'ABP',
    adr: {
      mode: 'on',
    },
    app_eui: '494b284ab1eca29b',
    app_key: null,
    appskey: null,
    band: 'LA915-928A',
    counters_size: 4,
    dev_addr: '5a27f147',
    dev_class: 'A',
    encryption: 'APP',
    nwkskey: '',
    rx1: {
      delay: 5,
    },
    device_type: 'test',
    contract_id: -1,
    strict_counter: 'True',
    dev_eui: '',
    block_downlink: 'True',
    block_uplink: 'True',
  },

};

const DevicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SHOW_DEVICES:
      return { ...state, devicesList: [...state.devicesList, ...action.payload] };

    case ACTION_TYPES.CHANGE_TARGET_KEYS:
      return { ...state, targetKeys: action.payload };

    case ACTION_TYPES.LIST_MIGRATE_DEVICE:
      return { ...state, migrateDevicesList: action.payload };

    case ACTION_TYPES.LOAD_MORE_DEVICES:
      return { ...state, loadMoreDevices: action.value };

    case ACTION_TYPES.CLEAR_DEVICES_LIST:
      return { ...state, devicesList: [] };

    case ACTION_TYPES.LOADING_TABLE:
      return { ...state, loading: action.value };

    case ACTION_TYPES.UPDATE_DEVICE:
      return {
        ...state,
        devicesList: state.devicesList.map((device) => {
          if (device.dev_eui === action.id) {
            return {
              activation: action.newData.activation,
              adr: action.newData.adr,
              app_eui: action.newData.app_eui,
              app_key: action.newData.app_key,
              appskey: action.newData.appskey,
              band: action.newData.band,
              block_downlink: action.newData.block_downlink,
              block_uplink: action.newData.block_uplink,
              contract_id: action.newData.contract_id,
              counter_down: action.newData.counter_down,
              counter_up: action.newData.counter_up,
              counters_size: action.newData.counters_size,
              dev_addr: action.newData.dev_addr,
              dev_class: action.newData.dev_class,
              dev_eui: action.newData.dev_eui,
              device_type: action.newData.device_type,
              encryption: action.newData.encryption,
              nwkskey: action.newData.nwkskey,
              rx1: action.newData.rx1,
              strict_counter: action.newData.strict_count,
              tags: action.newData.tags,
            };
          }
          return device;
        }),
      };

    case ACTION_TYPES.ADD_DEVICE:
      return {
        ...state,
        devicesList: [...state.devicesList, action.newDevice],
      };

    case ACTION_TYPES.DELETE_DEVICE:
      return {
        ...state,
        devicesList: state.devicesList.filter((device) => device.dev_eui !== action.dev_eui),
      };

    default:
      return state;
  }
};

export default DevicesReducer;
