import { all, takeLatest } from 'redux-saga/effects';

import { signIn, signOut } from './auth';
import {
  addUser,
  deleteUser,
  updateUser,
  changePassword,
  getOneUser,
  activateUser,
  get_all_users,
} from './users';
import {
  clientsActivation,
  getClientsAndUsers,
  addClient,
  removeClient,
  updateClient,
  getClientInfo,
  get_all_clients,
  get_providers_client,
} from './clients';
import {
  addDevices,
  deleteDevices,
  updateDevice,
  getDevicesAndContracts,
  activateDevices,
  migrateDevices,
  getMigrateDevices,
  getClientDevices,
  get_all_devices,
} from './devices';
import {
  createContracts,
  deleteContract,
  updateContract,
  getAllContracts,
  getOneContract,
} from './contracts';
import { getAllTags, createTag } from './tags';

export default function* rootSaga() {
  return yield all([
    // middlewares relacionados à autenticação
    takeLatest('SIGN_IN_REQUEST', signIn),
    takeLatest('LOGOUT', signOut),

    takeLatest('ASYNC_GET_CLIENTS_AND_USERS', getClientsAndUsers),
    takeLatest('ASYNC_GET_CLIENTS', get_all_clients),
    takeLatest('ASYNC_CLIENT_ACTIVATION', clientsActivation),
    takeLatest('ASYNC_ADD_CLIENT', addClient),
    takeLatest('ASYNC_DELETE_CLIENTS', removeClient),
    takeLatest('ASYNC_UPDATE_CLIENTS', updateClient),

    takeLatest('ASYNC_ADD_USER', addUser),
    takeLatest('ASYNC_GET_ALL_USERS', get_all_users),
    takeLatest('ASYNC_DELETE_USER', deleteUser),
    takeLatest('ASYNC_UPDATE_USER', updateUser),
    takeLatest('ASYNC_GET_ONE_USER', getOneUser),
    takeLatest('ASYNC_USER_ACTIVATION', activateUser),
    takeLatest('ASYNC_UPDATE_PASSWORD', changePassword),

    takeLatest('ASYNC_ADD_DEVICE', addDevices),
    takeLatest('ASYNC_DELETE_DEVICE', deleteDevices),
    takeLatest('ASYNC_UPDATE_DEVICE', updateDevice),
    takeLatest('ASYNC_GET_DEVICES_AND_CONTRACTS', getDevicesAndContracts),
    takeLatest('ASYNC_DEVICE_ACTIVATION', activateDevices),
    takeLatest('ASYNC_GET_DEVICES', get_all_devices),

    takeLatest('ASYNC_MIGRATE_DEVICE', migrateDevices),
    takeLatest('ASYNC_LIST_MIGRATE_DEVICE', getMigrateDevices),

    takeLatest('ASYNC_ADD_CONTRACT', createContracts),
    takeLatest('ASYNC_DELETE_CONTRACT', deleteContract),
    takeLatest('ASYNC_UPDATE_CONTRACT', updateContract),
    takeLatest('ASYNC_GET_ALL_CONTRACTS', getAllContracts),
    takeLatest('ASYNC_GET_ONE_CONTRACT', getOneContract),

    // middlewares relacionados ao dashboard do cliente
    takeLatest('ASYNC_GET_ONE_CLIENT', getClientInfo),
    takeLatest('ASYNC_GET_CLIENT_DEVICES', getClientDevices),
    takeLatest('ASYNC_GET_PROVIDERS_CLIENT', get_providers_client),

    takeLatest('ASYNC_GET_ALL_TAGS', getAllTags),
    takeLatest('ASYNC_ADD_TAG', createTag),
  ]);
}
