import styled from 'styled-components';

export const Container = styled.div`
  @import '~antd/dist/antd.css';

.checkbox-card{
  overflow: auto;
  position: relative;
}

.div-content{
  position: absolute;
  overflow: hidden;
}

#components-transfer-demo-table-transfer .ant-table td {
  background: transparent;
}

`;
