import { notification, message } from 'antd';
import { put } from 'redux-saga/effects';

import api from '../../services/api';
import { TagsActions } from '../../actions';

export function* getAllTags({ limit, offset }) {
  try {
    const { data } = yield api.get('/tags', { params: { limit, offset } })
      .then((response) => response);

    if (data.length > 0) {
      data.map((i) => Object.assign(i, { key: `${i.id}` }));
    }

    const sortedTags = data.sort(
      (a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
        return 0;
      },
    );

    yield put(TagsActions.save_tags(sortedTags));
  } catch (warning) {
    notification.warning({
      message: 'Sessão expirou!',
      description: 'Faça o login novamente.',
    });
  }
}

export function* createTag({ tagData }) {
  try {
    const { data } = yield api.post('/tags', tagData).then((response) => response);
    message.success('Tag registrada!');

    data.key = `${data.id}`;
    yield put(TagsActions.add_tag(data));
  } catch (error) {
    notification.error({
      message: 'Erro no registro!',
      // description: '',
    });
  }
}
