import { ACTION_TYPES } from '../../actions/authActions';

export const INITIAL_STATE = {
  token: localStorage.getItem('token') || null,
  u_type: localStorage.getItem('user') || null,
  signInLoading: false,
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        u_type: action.user_type,
        signInLoading: false,
      };

    case ACTION_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        u_type: null,
        signInLoading: false,
      };

    case ACTION_TYPES.LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        u_type: null,
      };

    case ACTION_TYPES.SIGN_IN_REQUEST_LOGIN:
      return {
        ...state,
        signInLoading: true,
      };
    default:
      return state;
  }
};

export default AuthReducer;
