import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Layout,
  Card,
  Typography,
} from 'antd';
// import { TagOutlined, ReloadOutlined } from '@ant-design/icons';

import { TagsActions, ClientsActions } from '../../actions';
// import LoagingDiv from '../../components/loading/loading';
import TagsTable from './TagsTable/tagsTable';
import NewTagModal from './NewTagModal/newTagModal';
import './styles.css';

const TagsView = () => {
  const dispatch = useDispatch();
  const { Title } = Typography;
  const {
    tagsList,
    loadMoreTags,
    createTagModalVisibility,
  } = useSelector((state) => state.TagsReducer);
  const { clientsList, loadMoreClients } = useSelector((state) => state.ClientsReducer);

  useEffect(() => {
    if (loadMoreClients) {
      dispatch(ClientsActions.async_get_clients(100, 0));
      dispatch(ClientsActions.enable_load_more_clients(false));
    }
  }, [loadMoreTags, tagsList, clientsList, createTagModalVisibility]);

  return (
    <div>
      <Title>Tags</Title>

      <Layout style={{ minWidth: '90vw' }}>
        <Card
          style={{ margin: 'auto', width: '100%', minHeight: '100vh' }}
        >
          <TagsTable />
        </Card>

        <NewTagModal
          visible={createTagModalVisibility}
          onCancel={() => dispatch(TagsActions.open_create_tag_modal(false))}
        />
      </Layout>

    </div>
  );
};

export default TagsView;
