export const connectionData = [
  {
    id: '87405988932b4db9b032',
    connection_type: 'HTTP',
    filter: 'b056fd41a594bdea7eca',
    app_url: 'http://exemplo.com/',
    description: 'Sistema de Controle de drones',
    authorization_header: 'YWxhZGRpbjpvcGVuc2zYW1j',
  },
  {
    id: '87405988932b4db9b032',
    connection_type: 'MQTT',
    filter: 'b056fd41a594bdea7eca',
    app_url: 'http://exemplo.com/',
    description: 'Sistema de Controle de drones',
    authorization_header: 'YWxhZGRpbjpvcGVuc2zYW1j',
  },
  {
    id: '87405988932b4db9b032',
    connection_type: 'Web Socket',
    filter: 'b056fd41a594bdea7eca',
    app_url: 'http://exemplo.com/',
    description: 'Sistema de Controle de drones',
    authorization_header: 'YWxhZGRpbjpvcGVuc2zYW1j',
  },
  {
    id: '87405988932b4db9b032',
    connection_type: 'HTTP',
    filter: 'b056fd41a594bdea7eca',
    app_url: 'http://exemplo.com/',
    description: 'Sistema de Controle de drones',
    authorization_header: 'YWxhZGRpbjpvcGVuc2zYW1j',
  },
  {
    id: '87405988932b4db9b032',
    connection_type: 'MQTT',
    filter: 'b056fd41a594bdea7eca',
    app_url: 'http://exemplo.com/',
    description: 'Sistema de Controle de drones',
    authorization_header: 'YWxhZGRpbjpvcGVuc2zYW1j',
  },
  { //    b056fd41a594bdea7eca
    id: '87405988932b4db9b032',
    connection_type: 'MQTT',
    filter: 'b056fd41a594bdea7eca',
    app_url: 'http://exemplo.com/',
    description: 'Sistema de Controle de drones',
    authorization_header: 'YWxhZGRpbjpvcGVuc2zYW1j',
  },
  {
    id: '87405988932b4db9b032',
    connection_type: 'Web Socket',
    filter: 'b056fd41a594bdea7eca',
    app_url: 'http://exemplo.com/',
    description: 'Sistema de Controle de drones',
    authorization_header: 'YWxhZGRpbjpvcGVuc2zYW1j',
  },

];

export const filterData = [
  {
    id: '87405988932b4db9b032',
    devices_tag: ['tag1', 'tag2', 'tag3', 'tag4', 'tag5'],
    dev_eui: ['dev1', 'dev2', 'dev3', 'dev3', 'dev4', 'dev5'],
    app_eui: ['app_eui 1', 'app_eui 2', 'app_eui 3', 'app_eui 4', 'app_eui 5', 'app_eui 6'],
    gateways: ['gateway 1', 'gateway 2', 'gateway 3', 'gateway 4', 'gateway 5', 'gateway 6'],
    message_types: ['json_request', 'uplink', 'downlink', 'error', 'warning', 'info', 'location', 'status_response'],
    options: ['Duplicates', 'Radio data', 'Lora data'],
    limit: ['SEM LIMITE'],
  },
  {
    id: '87405988932b4db9b032',
    devices_tag: ['tag1', 'tag2', 'tag3', 'tag4', 'tag5'],
    dev_eui: ['dev1', 'dev2', 'dev3', 'dev3', 'dev4', 'dev5'],
    app_eui: ['app_eui 1', 'app_eui 2', 'app_eui 3', 'app_eui 4', 'app_eui 5', 'app_eui 6'],
    gateways: ['gateway 1', 'gateway 2', 'gateway 3', 'gateway 4', 'gateway 5', 'gateway 6'],
    message_types: ['json_request', 'uplink', 'downlink', 'error', 'warning', 'info', 'location', 'status_response'],
    options: ['Duplicates', 'Radio data', 'Lora data'],
    limit: ['50'],
  },
  {
    id: '87405988932b4db9b032',
    devices_tag: ['tag1', 'tag2', 'tag3', 'tag4', 'tag5'],
    dev_eui: ['dev1', 'dev2', 'dev3', 'dev3', 'dev4', 'dev5'],
    app_eui: ['app_eui 1', 'app_eui 2', 'app_eui 3', 'app_eui 4', 'app_eui 5', 'app_eui 6'],
    gateways: ['gateway 1', 'gateway 2', 'gateway 3', 'gateway 4', 'gateway 5', 'gateway 6'],
    message_types: ['json_request', 'uplink', 'downlink', 'error', 'warning', 'info', 'location', 'status_response'],
    options: ['Duplicates', 'Radio data', 'Lora data'],
    limit: ['50'],
  },
];
