import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Input,
  Button,
  Modal,
  Typography,
  Divider,
  Select,
} from 'antd';
import {
  LockOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import { Container } from './styles';
import { UsersActions } from '../../../actions';

function UserRegister() {
  // Componentes do antd
  const { confirm } = Modal;
  const { Title } = Typography;
  const { Option } = Select;
  const [form] = Form.useForm();

  const { clientsList } = useSelector((state) => state.ClientsReducer);
  const dispatch = useDispatch();

  const [userType, setUserType] = useState(false);

  async function onFinish({
    name, email, password, client_id, user_type_id,
  }) {
    confirm({
      title: 'Deseja confirmar o registro do usuário?',
      icon: <ExclamationCircleOutlined />,

      onOk() {
        dispatch(UsersActions.async_add_user({
          name,
          email,
          password,
          client_id,
          user_type_id,
          is_active: true,
        }));
      },
    });
  }

  function handleChangeUserType(value) {
    if (value === 1) {
      setUserType(true);
    } else {
      setUserType(false);
    }
  }

  return (
    <Container>
      <Title>Registro de Usuário</Title>

      <Divider />

      <Form
        form={form}
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 14 }}
        layout="vertical"
        onFinish={onFinish}
        style={{
          textAlign: 'left',
          padding: 30,
          width: 1000,
          margin: '20px 25%',
        }}
      >

        <Form.Item
          label="Tipo do usuário"
          name="user_type_id"
          rules={[
            {
              required: true,
              message: 'Por favor selecione o cliente!',
            },
          ]}
        >
          <Select size="large" onChange={handleChangeUserType}>
            <Option value={1}>Adminstrador</Option>
            <Option value={2}>Cliente</Option>
            <Option value={3}>Fornecedor</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Nome do usuário"
          name="name"
          rules={[
            {
              required: true,
              message: 'Por favor adicione o username',
            },
          ]}
        >
          <Input
            size="large"
            prefix={<UserOutlined />}
          />
        </Form.Item>

        <Form.Item
          label="Cliente"
          name="client_id"
          rules={[
            {
              required: !userType,
              message: 'Por favor selecione o cliente!',
            },
          ]}
        >
          <Select
            showSearch
            disabled={userType}
            size="large"
            style={{ width: 200 }}
            placeholder="Selecione o Cliente"
            optionFilterProp="children"
            filterOption={
              (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
          >
            {clientsList.map((client) => (
              <Option key={client.key} value={client.id}>
                {client.company_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Email inválido!',
            }, {
              required: true,
              message: 'Por favor digite seu email!',
            }]}
        >
          <Input
            size="large"
            prefix={<UserOutlined />}
            placeholder="exemplo@email.com"
          />
        </Form.Item>

        <Form.Item
          label="Senha do usuário"
          name="password"
          tooltip={{
            title: 'A senha deve conter pelo menos 6 dígitos, uma letra [a - Z] e um número.',
            icon: <InfoCircleOutlined />,
          }}
          rules={[
            {
              required: true,
              message: 'Por favor digite a senha!',
            },
            () => ({
              validator(rule, value) {
                const pattern = /[A-Za-z]/g;
                const numberPattern = /[0-9]/g;
                if (
                  value.match(pattern).length > 0
                  && value.match(numberPattern).length > 0
                  && value.length >= 6) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('A senha deve conter pelo menos 6 dígitos, uma letra e um número!');
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined />}
            placeholder="exemploDeSenha123"
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirme a Senha"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Por favor confirme a senha!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('As senhas estão diferentes!');
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            prefix={<LockOutlined />}
            placeholder="exemploDeSenha123"
          />
        </Form.Item>

        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
            >
              Registrar
            </Button>
          )}
        </Form.Item>
      </Form>
    </Container>
  );
}

export default UserRegister;
