import React from 'react';
import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

// Páginas
import Dashboard from '../views/Dashboard/dashboard';
import Login from '../views/Login/login';
import ClientView from '../views/ClientView';
import history from './history';
// Tipos de rotas
import PrivateRoue from './private';
import GuestRoute from './guest';
import ClientPrivateRoute from './clientRoute';

// temporário

const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <GuestRoute path="/login" component={Login} />
      <ClientPrivateRoute path="/home-view" component={ClientView} />
      <PrivateRoue path="/" component={Dashboard} />
    </Switch>
  </ConnectedRouter>
);

export default Routes;
