import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  InputNumber,
} from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import EditableTagGroup from '../../ClientView/devices/tagsUpdate';
import { DevicesActions } from '../../../actions';

function UpdateDeviceForm({
  devInfo, visible, onCancel,
}) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {
    dev_eui,
    tags,
    app_eui,
    dev_class,
    encryption,
    activation,
    counters_size,
    nwkskey,
    dev_addr,
    appskey,
    app_key,
    strict_counter,
    band,
    adr,
    rx1,
  } = devInfo;

  const { contract_id } = devInfo || 'Sem contrato';
  const { mode } = adr || 'on';
  const { delay } = rx1 || '';

  const { clientsList } = useSelector((state) => state.ClientsReducer);
  const { contractsList } = useSelector((state) => state.ContractsReducer);

  const [clientContracts, setClientContacts] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [listOfTags, setListOfTags] = useState([]);

  const [activeABP, setActiveABP] = useState(activation);
  const [encryptNS, setEncryptNS] = useState(encryption);

  async function handleUpdateDevice(values) {
    setConfirmLoading(true);

    form
      .validateFields()
      .then(() => {
        form.resetFields();

        dispatch(DevicesActions.async_update_device(dev_eui, {
          contract_id: values.contract_id,
          tags: listOfTags,
          app_eui: values.app_eui,
          dev_class: values.dev_class,
          encryption: values.encryption,
          activation: values.activation,
          counters_size: values.counters_size,
          nwkskey: values.nwkskey,
          dev_addr: values.dev_addr,
          appskey: values.appskey,
          app_key: values.app_key,
          strict_counter: values.strict_counter,
          band: values.band,
          adr: { mode: values.mode },
          rx1: { delay: values.delay },
        }));
      });

    setConfirmLoading(false);
  }

  function handleUpdateTags(newTags) {
    setListOfTags(newTags);
  }

  function findClientContractById(client_id) {
    const clientContract = contractsList.filter(
      (contract) => client_id === contract.client_id,
    );

    setClientContacts(clientContract);
  }

  useEffect(() => {}, [listOfTags]);

  return (
    <>
      <Modal
        visible={visible}
        title={`Edição do dispositivo ${dev_eui}`}
        key={`dsadasd${dev_eui}`}
        closeIcon={<CloseCircleOutlined style={{ color: 'red' }} />}
        cancelText="Cancelar"
        confirmLoading={confirmLoading}
        onCancel={onCancel}
        footer={null}
      >
        <Form
          form={form}
          key={`${dev_eui}form-dev`}
          layout="vertical"
          hideRequiredMark
          onFinish={handleUpdateDevice}
          initialValues={{
            contract_id,
            dev_eui,
            tags,
            app_eui,
            dev_class,
            encryption,
            activation,
            counters_size,
            nwkskey,
            dev_addr,
            appskey,
            app_key,
            strict_counter,
            band,
            mode,
            delay,
          }}
        >
          <Row gutter={16}>
            <Col>
              <Form.Item
                label="Cliente"
                name="client_id"
                rule={[
                  {
                    required: false,
                    message: 'Selecione um cliente',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Selecione o cliente"
                  optionFilterProp="children"
                  onChange={(value) => findClientContractById(value)}
                  filterOption={
                    (input, option) => option.children.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
}
                >
                  {clientsList.map(
                    (client) => (
                      <Option key={client.id} value={client.id}>
                        {client.company_name}
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                label="Contrato"
                name="contract_id"
                rules={[
                  {
                    required: false,
                    message: 'Por favor selecione um contrato!',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Selecione o Contrato"
                  optionFilterProp="children"
                >
                  {clientContracts.map((contract) => (
                    <Option key={contract.id} value={contract.id}>
                      {contract.id}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="App EUI"
                name="app_eui"
                rules={[{
                  required: true,
                  message: 'Por favor, insira o cpf ou CNPJ！',
                }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Classe do dispositivo"
                name="dev_class"
                rules={[{
                  required: true,
                  message: 'Insira a ！',
                }]}
              >
                <Select placeholder="Dev">
                  <Option key="A" value="A">A</Option>
                  <Option key="C" value="C">C</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Encriptação"
                name="encryption"
                rules={[{
                  required: true,
                  message: 'Por favor selecione a encriptação!',
                }]}
              >
                <Select
                  placeholder="Encryption"
                  onChange={(value) => setEncryptNS(value)}
                >
                  <Option key="APP" value="APP">APP</Option>
                  <Option key="NS" value="NS">NS</Option>
                </Select>
              </Form.Item>

            </Col>

            <Col span={12}>
              <Form.Item
                label="Modo de ativação"
                name="activation"
                rules={[{
                  required: true,
                  message: 'Selecione o modo de ativação',
                }]}
              >
                <Select
                  onChange={(value) => setActiveABP(value)}
                >
                  <Option key="ABP" value="ABP">ABP</Option>
                  <Option key="OTAA" value="OTAA">OTAA</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col Col span={12}>
              <Form.Item
                label="Network session Key"
                name="nwkskey"
                rules={[{
                  required: activeABP === 'ABP',
                  message: 'Por favor adicione o NwsKey!',
                }]}
              >
                <Input
                  placeholder="Nwskey"
                  disabled={activeABP === 'OTAA'}
                  maxLength="32"
                />
              </Form.Item>
            </Col>

            <Col Col span={12}>
              <Form.Item
                label="Dev Address"
                name="dev_addr"
                rules={[
                  {
                    required: activeABP === 'ABP',
                    message: 'Por favor adicione o Device Address!',
                  },
                ]}
              >
                <Input
                  placeholder="Dev Address"
                  disabled={activeABP === 'OTAA'}
                  maxLength="8"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="App session KEY"
                name="appskey"
                rules={[
                  {
                    required: (encryptNS === 'NS' && activeABP === 'ABP'),
                    message: 'Por favor adicione o Apps Key!',
                  },
                ]}
              >
                <Input
                  placeholder="Apps KEY"
                  maxLength="32"
                  disabled={!(encryptNS === 'NS' && activeABP === 'ABP')}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="App Key"
                name="app_key"
                rules={[
                  {
                    required: false,
                    message: 'Por favor adicione o App Key!',
                  },
                ]}
              >
                <Input
                  placeholder="App KEY"
                  maxLength="32"
                  disabled={!(encryptNS === 'NS' && activeABP === 'OTAA')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Counter Size"
                name="counters_size"
                rules={[
                  {
                    required: true,
                    message: 'Por favor selecione o Counter Size!',
                  },
                ]}
              >
                <Select placeholder="CsetEncryptNSounter" defaultValue={counters_size}>
                  <Option key="counter-2" value={2}>2</Option>
                  <Option key="counter-4" value={4}>4</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                label="Modo ADR"
                name="mode"
              >
                <Select style={{ width: '220px' }}>
                  <Option key="on-adr-mode" value="on">Ativado</Option>
                  <Option key="off-adr-mode" value="off">Desativado</Option>
                  {/* <Option key="static-adr-mode" value="static">Estático</Option> */}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col>
              <Form.Item label="Banda" name="band">
                <Select style={{ width: '220px' }}>
                  <Option value="LA915-928A">LA915-928A</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                label="Rx1"
                name="delay"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <InputNumber style={{ width: '220px' }} min={0} max={100000} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                label="Tags"
                name="tags"
                rules={[
                  {
                    required: false,
                    message: 'Selecione uma tag!',
                    type: 'array',
                  },
                ]}
              >
                <EditableTagGroup
                  newtags={handleUpdateTags}
                  oldTags={tags}
                />
              </Form.Item>
            </Col>

          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Salvar
            </Button>
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
}

UpdateDeviceForm.propTypes = {
  devInfo: PropTypes.objectOf.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  // onUpdate: PropTypes.func.isRequired,
};

export default UpdateDeviceForm;
