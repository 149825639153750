export const ACTION_TYPES = {
  ASYNC_ADD_TAG: 'ASYNC_ADD_TAG',
  ASYNC_GET_ALL_TAGS: 'ASYNC_GET_ALL_TAGS',

  SAVE_TAGS: 'SAVE_TAGS',
  ADD_TAG: 'ADD_TAG',
  CLEAR_TAGS_LIST: 'CLEAR_TAGS_LIST',
  LOAD_MORE_TAGS: 'LOAD_MORE_TAGS',
  OPEN_CREATE_TAG_MODAL: 'OPEN_CREATE_TAG_MODAL',
  REFRESH_TAGS_TABLE: 'REFRESH_TAGS_TABLE',
};

export const add_tag = (newTagData) => ({
  type: ACTION_TYPES.ADD_TAG,
  newTagData,
});

export const save_tags = (listOfTags) => ({
  type: ACTION_TYPES.SAVE_TAGS,
  listOfTags,
});

export const refresh_table = () => ({
  type: ACTION_TYPES.REFRESH_TAGS_TABLE,
});

export const clear_tags_list = () => ({
  type: ACTION_TYPES.CLEAR_TAGS_LIST,
});

export const load_more_tags = (value) => ({
  type: ACTION_TYPES.LOAD_MORE_TAGS,
  value,
});

export const open_create_tag_modal = (visibility) => ({
  type: ACTION_TYPES.OPEN_CREATE_TAG_MODAL,
  visibility,
});

export const async_add_tag = (tagData) => ({
  type: ACTION_TYPES.ASYNC_ADD_TAG,
  tagData,
});

export const async_get_all_tags = (limit, offset) => ({
  type: ACTION_TYPES.ASYNC_GET_ALL_TAGS,
  limit,
  offset,
});
